<template>
	<!-- 简介 -->
	<div class="Introduction padding-tb-15">
		<div class="IntroductionBox">
			<div class="Introduction-img">
				<img style="width: 100%;height: 100%;" :src="shopInformation.fShopLogoPath == '' ? imgurl : shopInformation.fShopLogoPath"
				 alt="">
			</div>
			<div class="Introduction-title flex-row-align-center">
				店铺星级<el-rate v-model="starLevel" :colors="['#FF3300','#FF3300','#FF3300']" :disabled-void-color="'#e4e4e4'" disabled></el-rate>
			</div>
			<div class="Introduction-good">
				<p>好评度<span>{{shopInformation.fEvaluationScore}}%</span></p>
			</div>
		</div>
		<div class="Introduction-con">
			<!-- <div :class="showAll?'height-fixed':'height-auto'"> -->
			<div class="Introduction-con-box" v-for="(item,index) in ShopProfileList" :key='index'>
				<p class="Introduction-con-title">{{item.fTitle}}</p>
				<div class="shop-brief-introduction">
					{{item.fContent}}
				</div>
			</div>
			<!-- </div> -->
			<!-- <div class="flex-row-center-center font-color-ccc pointer" @click="showAll=!showAll">
				<div :class="showAll?'el-icon-arrow-down':'el-icon-arrow-up'"></div>
			</div> -->
		</div>
		<div class="Introduction-msg">
			<p>基本信息</p>
			<div>店铺名称<span>{{Introductions.fShopName}}</span></div>
			<div>所在地区<span>{{Introductions.fAddress}}</span></div>
			<!-- <div class="Introduction-qian">资质<span>{{}}</span></div> -->
			<div>开店时间<span>{{Introductions.fBeginDate}}</span></div>
			<div>销售品牌<span>{{Introductions.fBrands}}</span></div>
		</div>
		<div class="Introduction-business">
			<p class="Introduction-business-inner-text">企业资质</p>
			<div class="flex-row-wrap width-fill padding-lr-15">
				<div class="Introduction-business-inner-box padding-10 color-theme font-size14 position-relative" v-for="(item,index) in Qualification"
				 :key='index'>
					<div class="position-relative Introduction-business-inner-box-title hide-text">
						{{item.fClassPropertieData}}
					</div>
					<div class="download-qualifications flex-row">
						<div class="pointer margin-r-20" @click="examineQualifications(item)">查看资质</div>
						<div class="pointer" @click="downloadQualifications(item)">下载</div>
					</div>
					<img class="image-style position-absolute" src="../../assets/imgs/goods/ZZRZ.png">
				</div>
			</div>
		</div>
		<el-dialog :title="dialogTitle" :top="'10vh'" :visible.sync="centerDialogVisible" v-loading='dialogLoading' width="70%" center>
			<div class="dialog-box">
				<div v-for="(item,index) in downloadList" :key='index' class="margin-b-10">
					<img style="max-width: 100%;" :src="item.fFullPath">
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
			<!-- 	<el-button @click="centerDialogVisible = false">取消</el-button>
				<el-button type="primary" @click="centerDialogVisible = false">下载</el-button> -->
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: 'Header',
		props: {
			Introductions: {
				type: Object,
				default: function() {
					return {};
				}
			},
			Qualification: {
				type: Array,
				default: function() {
					return [];
				}
			},
			AdList: {
				type: Array,
				default: function() {
					return [];
				}
			},
			ShopProfileList: {
				type: Array,
				default: function() {
					return [];
				}
			},
			shopInformation: {
				type: Object,
				default: function() {
					return {};
				}
			},
			shopID: {
				type: [String, Number],
				default: -1
			}
		},
		data() {
			return {
				showAll: false,
				imgurl: require('@/assets/shoplogo.png'),
				starLevel: 0,
				centerDialogVisible: false,
				dialogTitle: '',
				dialogLoading: false,
				downloadList: []
			}
		},
		
		mounted() {},
		beforeUpdate(){
			this.Star();//获取店铺星级
		},
		methods: {
			//获取店铺星级
			Star(){
				console.log(this.shopInformation,"简介星级")
				if(this.shopInformation.fEvaluationScore != ''){
					let sums = this.shopInformation.fEvaluationScore
					this.starLevel = sums/20;
				}
			},
			examineQualifications(e) {
				this.dialogLoading = true;
				console.log(e);
				this.dialogTitle = e.fClassPropertieData;
				this.ApiRequestPost('api/mall/ebshop/qualification/get-list-by-productFactory', {
					fProductFactoryID: e.fClassPropertieDataID,
					fShopID: this.shopID
				}).then(
					res => {
						console.log('企业资质', res.obj);
						this.downloadList = res.obj;
						if (this.downloadList.length > 0) {
							this.centerDialogVisible = true;
						}
						this.dialogLoading = false;
					},
					error => {}
				);
			},
			downloadQualifications(e){
				console.log(e,'下载资质');
				this.ApiRequestPost('api/mall/ebshop/qualification/down-zip-by-froduct-factoryid', {
					fProductFactoryID: e.fClassPropertieDataID,
					fShopID: this.shopID
				}).then(
					res => {
						console.log(res);
						if(res.obj){
							window.open(res.obj)
						}
					},
					error => {}
				);
			}
		}
	}
</script>

<style lang="scss">
	.Introduction {
		width: 1099px;
		background: #f8f9fe;
		margin: 0 auto 15px;
	}

	.IntroductionBox {
		display: flex;
	}

	.Introduction-img {
		width: 40px;
		height: 40px;
		background: #ffffff;
		border-radius: 5px;
		margin: 5px 0px 0px 15px;
	}

	.Introduction-title {
		width: 170px;
		height: 19px;
		border-radius: 20px;
		font-size: 11px;
		font-weight: 400;
		text-align: left;
		color: black;
		margin-top: 13px;
		padding-left: 5px;
		margin-left: 10px;
	}

	.Introduction-good p {
		font-size: 12px;
		color: #000000;
		margin-left: 770px;
		margin-top: 16px;
	}

	.Introduction-good span {
		font-size: 12px;
		color: #0173FE;
		margin-left: 5px;
	}

	.Introduction-con {
		width: 1074px;
		background: #ffffff;
		border-radius: 10px;
		padding-bottom: 15px;
		margin-left: 13px;
		margin-top: 17px;

		.height-fixed {
			max-height: 196px;
			overflow: hidden;
		}
	}

	.Introduction-con-title {
		font-weight: bold;
		color: #0173fe;
		margin: 0px 0px 13px 0px;
		padding-top: 15px;
	}

	.Introduction-con-box {
		font-weight: 400;
		color: #666666;
		line-height: 22px;
		font-size: 13px;
		padding: 0px 15px;

		.shop-brief-introduction {
			text-indent: 2em;
		}
	}

	.Introduction-msg {
		padding-bottom: 5px;
		width: 1073px;
		// height: 217px;
		background: #ffffff;
		border-radius: 10px;
		margin-left: 13px;
		margin-top: 11px;
	}

	.Introduction-msg p {
		font-weight: bold;
		color: #0173fe;
		margin: 0px 0px 13px 15px;
		padding-top: 15px;
	}

	.Introduction-msg div {
		font-size: 13px;
		font-weight: 400;
		color: #000000;
		margin-left: 15px;
		margin-bottom: 16px;
	}

	.Introduction-msg span {
		font-size: 13px;
		font-weight: 400;
		color: #666666;
		margin-left: 23px;
	}

	.Introduction-business {
		width: 1073px;
		// height: 214px;
		background: #ffffff;
		border-radius: 10px;
		margin: 15px 0px 30px 14px;
		padding-bottom: 15px;

	}

	.Introduction-business-inner-text {
		font-weight: bold;
		color: #0173fe;
		padding-top: 15px;
		margin-left: 14px;
	}

	.Introduction-business-inner-box {
		width: 160px;
		height: 40px;
		// border: 1px solid #F2F2F2;
		margin-top: 15px;
		margin-right: 34px;
		background-color: #ecf5ff;
		// float: left;
	}


	.Introduction-qian span {
		margin-left: 50px;
	}

	.download-qualifications {
		text-decoration: underline
	}

	.Introduction-business-inner-box-title {
		z-index: 1;
		width: 160px;
	}

	.image-style {
		width: 40px;
		top: 10px;
		right: 10px;
	}

	.dialog-box {
		max-height: 600px;
		overflow-y: scroll;
	}
	.el-dialog--center .el-dialog__body{
		padding-top: 0;
		padding-bottom: 0;
	}
</style>
