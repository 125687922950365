<template>
  <!-- 优惠券活动 -->
  <div>
    <div class="top_bar margin-b-20 flex-row margin-t-5"  v-if="couponList.length > 0">
      <div class="top-left_bar">先领券   再购物GO</div>
      <img src="@/assets/collectpar.png" alt="">
    </div>
    <div v-if="couponList.length > 0" class="width-1100 flex-row  margin-b-20 pointer center_div" @click="receiveClick">
      <div class="flex-row">
        <div class="assume_bg"  v-for="(item, index) in couponList" :key="index">
          <div class="assume_playment">￥{{ item.fDeductionMoney }}</div>
          <div class="assume_card hide-text">{{ item.fCouponName }}</div>
          <div class="assume_button coupon-bg-FFCCCC" style="background:#CCCCCC" v-if="item.fSurplusSum ==0 && item.fStatus == -1">已抢光</div>
          <div :style="item.fStatus == 0 || item.fStatus == 1 ? 'background:#CCCCCC' : ''" class="assume_button coupon-bg-FFCCCC" v-else>{{ item.fStatus == 0 || item.fStatus == 1 ? '已领' : '领取' }}</div>
        </div>
      <!-- <div v-for="(item, index) in couponList" :key="index"
        class="coupon-box-two padding-lr-13 flex-column-center-start">
        <div class="coupon-box-two-r-border" v-if="index + 1 != couponList.length"></div>
        <div class="coupon-content-two-top flex-row-space-between-center">
          <div class="coupon-text-red font-size22 font-weight700">￥{{ item.fDeductionMoney }}</div>
          <div v-if="item.fSurplusSum ==0 && item.fStatus == -1"
            class="coupon-content-two-btn font-color-fff font-size12 flex-row-center-center coupon-bg-FFCCCC">
            已抢光
          </div>
          <div v-else class="coupon-content-two-btn font-color-fff font-size12 flex-row-center-center"
            :class="item.fStatus == 0 || item.fStatus == 1 ? 'coupon-bg-FFCCCC' : 'coupon-bg-red'">
            {{ item.fStatus == 0 || item.fStatus == 1 ? '已领' : '领取' }}
          </div>
        </div>
        <div class="font-size12 font-color-666 margin-t-6 hide-text width-fill">
          {{ item.fCouponName }}
        </div>
      </div> -->
      </div>
      <div class="coupon-box-one coupon-bg-red flex-row-align-center padding-lr-13">
        <div class="flex-colum-between font-color-fff font-weight700">
          <div class="font-size24">领取</div>
          <div class="font-size24">更多</div>
        </div>
        <img src="@/assets/jiantou.png" style="width: 21px;height: 23px;margin-left:10px" alt="">
        <!-- <div class="iconfont icon-jiantoux font-color-fff font-size18"></div> -->
      </div>
    </div>
    <div class="box-one bg-fff margin-b-20 padding-20" v-if="isshowhuodong">
      <div class="flex-row-space-between">
        <div
          style="position: relative;width: 100%; height: 40px;margin-bottom: 10px;line-height: 40px;padding: 0 10px;">
          {{huodongbgpath}}
          <img v-if="huodongbgpath != '' && huodongbgpath != null && huodongbgpath != undefined" :src="huodongbgpath"
            alt="" style="position: absolute;left: 0;top: 0;z-index: 1;width: 100%;height: 100%;">
          <div style="position: absolute;z-index: 2;width: 100%;top: 0;">
            <div class="font-size18 font-weight700" style="display: flex; flex-direction: row;">
              {{huodongtitle}}
            </div>
            <div class="flex-row-align-center pointer " style="position: absolute;right: 10px;top: 0px;"
              @click="LimitedActivities">
              <span class="font-size24 font-weight700 font-color-FF0000 margin-r-10">{{showhuodongtext}}</span>
              <countDowm :index="1" :remainTime="countDownTimeactivity" @countDownEnd="countDownEndactivity" :width="22"
                :height="22" :fontWeight="700" :fontSize="14"
                style="margin-left: 10px;margin-top: 0px;margin-right: 10px; line-height: 22px;" numberColor="#fff"
                bgColor="#ff0000" colonColor="#ff0000" :paddingRL="4"></countDowm> 更多
              <div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
            </div>
          </div>

        </div>
      </div>
      <div class="flex-row-space-between-center margin-t-10">
        <div class="box-one-row-item-full-image margin-r-15"
          v-if=" huodongtitleimg != '' && huodongtitleimg != undefined && huodongtitleimg != null">
          <img class="box-one-row-full-image" :src="huodongtitleimg"></img>
        </div>
        <div class="box-one-row-carousel box-row-carousel-two">
          <el-carousel height="260px" :autoplay="false" arrow="always" :loop="false">
            <el-carousel-item v-for="(list, indexList) in huodongList" :key="indexList">
              <div class="flex-row-align-center">
                <div class="box-one-row-item-box pointer" :class="{'margin-r-10':(indexList+1)%5!=0}"
                  v-for="(item1, indexItem1) in list" :key="indexItem1" @click="jumpGoodDetails(item1)">
                  <div class="box-one-row-item flex-colum-space-between">

                    <div class="box-one-row-picture-box flex-row-center-center" style="position: relative;">
                      <!-- 0右上角 -->
                      <div class="box-one-row-buoy"
                        v-if="huodongimgisarea == 0 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
                        <img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
                      </div>
                      <!-- 1右下角 -->
                      <div class="box-one-row-buoy1"
                        v-if="huodongimgisarea == 1 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
                        <img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
                      </div>
                      <!-- 2左下角 -->
                      <div class="box-one-row-buoy2"
                        v-if="huodongimgisarea == 2 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
                        <img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
                      </div>
                      <!-- 3左上角 -->
                      <div class="box-one-row-buoy3"
                        v-if="huodongimgisarea == 3 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
                        <img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
                      </div>

                      <img class="box-one-row-picture" :src="item1.goods.fPicturePath"></img>

                    </div>
                    <div class="box-one-row-content-box padding-10 flex-colum-between">
                      <div class="box-one-row-content-title font-size13 twoline-show-hide">
                        {{ item1.goods.fGoodsFullName }}
                      </div>
                      <div class="box-one-row-content-price font-size16 font-weight700">
                        ￥ <span
                          v-if='item1.goods.fGoodsMinPrice == item1.goods.fGoodsPrice ? false:true'>{{ item1.goods.fGoodsMinPrice }}~</span><span>{{ item1.goods.fGoodsPrice }}</span>
                        元/{{ item1.goods.fUnitName }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import countDowm from '@/components/countDown/countDown.vue';
import {
  mapActions
} from 'vuex';
import {
  mapGetters
} from 'vuex';

export default {
  components: {
    countDowm,
  },
  props: {
  },
  data () {
    return {
      fStatus: 0,
      // 优惠卷
      couponList: [],
      // 活动板块
      huodongImg: "",
      huodongtitle: "",
      huodongList: [],
      scrollLeft: 0,
      huodongtitleimg: "",
      huodongimgisarea: 0,
      showhuodongtext: "",
      isshowhuodong: false,
      countDownTimeactivity: 0,
      huodongbgpath: "", //活动首页顶部背景图
      fShopUnitID: '',
      cityGovernmenAreaID: '',
    }
  },
  created () {
    this.fShopUnitID = this.$route.query.id
    console.log(this.fShopUnitID, '店铺Id')
    this.queryCouponList();
    this.getLastCity()
  },
  methods: {
    // 跳转商品详情
			jumpGoodDetails(item) {
        console.log(item)
				this.$router.push({
					name: 'GoodsDetails',
					query: {
						fGoodsID: item.goods.fGoodsID
					}
				})
			},
    // 获取 城市 区域ID
    getLastCity () {
      this.ApiRequestPostNOMess('api/mall/sys/account/get-last-city', {})
        .then(res => {
          this.cityGovernmenAreaID = res.city;
          this.queryHuodongList()
        })
        .catch(err => { });
    },
    // 获取优惠券数据 {
    queryCouponList () {
      this.ApiRequestPost('api/mall/ebactivity/customerreceiverecord/get-page-coupon-info-shop', { fShopUnitID: this.fShopUnitID })
        .then(res => {
          console.log(res, 111111111);
          this.couponList = res.obj.slice(0, 4);
          this.fStatus = res.obj[0].fStatus;
        })
        .catch(err => { });
    },
    countDownEndactivity () {
      setTimeout(this.queryHuodongList(), 500)
    },
    // 跳转活动
    LimitedActivities () {
      this.$router.push({
        name: 'LimitedShopActivities',
        query: { id: this.fShopUnitID }
      });
    },
    //领券点击事件
    receiveClick () {
      if (this.$store.getters.getUserInfo == null) {
        return this.$store.dispatch('setShowLogin', true);
      }
      const status = this.$store.getters.getUserInfo.fVerifiedStatus;
      if (!status) {
        return this.$store.dispatch('setShowAttestation', true);
      }
      // this.$router.push('/CouponCollection');
      this.$router.push({
        name: 'CouponCollectionShop',
        query: { id: this.fShopUnitID }
      });
    },
    // 重组列表  返回以每 num 个为一组的数据  如果其他页面用的多可写入public.js
    makeListForNum (list, num) {
      console.log(list);
      console.log('获取初始数据')
      let returnLIst = [];
      for (var i = 0; i < list.length; i += num) {
        returnLIst.push(list.slice(i, i + num));
      }
      console.log(returnLIst);
      console.log('获取整理后的数组。')
      return returnLIst;
    },
    // 获取活动数据
    queryHuodongList () {
      // 请求:
      //     "fGovernmenAreaID": 0,
      // "fAppTypeID": 0,
      // "fGoodsClassID": 0,
      // "fShopUnitID": 0,
      // "skipCount": 0,
      // "maxResultCount": 0,
      // "filter": "string",
      // "sorting": "string"

      this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-activity-goods-list-shop', {
        fGovernmenAreaID: this.cityGovernmenAreaID,
        fShopUnitID: this.fShopUnitID,
        fAppTypeID: 2,
        skipCount: 0,
        maxResultCount: 15,
        filter: ""
      }).then(res => {
        // alert(1)
        let obj = res.obj;
        if (obj.couponInfo == null || obj.goods == null || obj.goodsClass == null) {
          this.isshowhuodong = false
          return;
        } else {
          this.huodongList = this.makeListForNum(obj.goods, 5);
          console.log(this.huodongList, '活动的列表数据')
          this.huodongImg = obj.couponInfo.fIconPath; //活动商品左上角图
          this.huodongtitle = obj.couponInfo.fEventName;
          this.huodongtitleimg = obj.couponInfo.fTitlePicPath; //顶部图片
          this.huodongimgisarea = obj.couponInfo.fDispArea; //活动图在左边还是右边
          this.huodongbgpath = obj.couponInfo.fBackGroundPicPath;
          if (this.showactivitytimeobj != "") {
            this.isshowhuodong = true
            // 大型活动
            if (new Date().getTime() >= new Date(res.obj.couponInfo.fRemindTime).getTime() &&
              new Date()
                .getTime() < new Date(
                  res.obj.couponInfo.fBeginTime).getTime()) {
              console.log(new Date(res.obj.couponInfo.fBeginTime).getTime() - new Date()
                .getTime())
              this.showhuodongtext = "优惠券距开始:"
              this.countDownTimeactivity = (new Date(res.obj.couponInfo.fBeginTime).getTime() -
                new Date()
                  .getTime())
            }
            if (new Date().getTime() >= new Date(res.obj.couponInfo.fBeginTime).getTime() &&
              new Date()
                .getTime() < new Date(
                  res.obj.couponInfo.fEndTime).getTime()) {
              console.log(new Date(res.obj.couponInfo.fEndTime).getTime() - new Date().getTime())
              this.showhuodongtext = "优惠券距结束:"
              this.countDownTimeactivity = (new Date(res.obj.couponInfo.fEndTime).getTime() -
                new Date()
                  .getTime())
                  console.log(this.countDownTimeactivity)
            }
            // 积分换购
            if (new Date().getTime() >= new Date(this.showactivitytimeobj.fRemindTime).getTime() &&
              new Date()
                .getTime() <
              new Date(this.showactivitytimeobj.fBeginTime).getTime()) {
              console.log(new Date(this.showactivitytimeobj.fBeginTime).getTime() - new Date()
                .getTime())
              this.isDistanceStartTime = true;
              this.countDownTime = (new Date(this.showactivitytimeobj.fBeginTime).getTime() -
                new Date()
                  .getTime())
            }
            if (new Date().getTime() >= new Date(this.showactivitytimeobj.fBeginTime).getTime() &&
              new Date()
                .getTime() <
              new Date(this.showactivitytimeobj.fEndTime).getTime()) {
              console.log(new Date(this.showactivitytimeobj.fEndTime).getTime() - new Date()
                .getTime())
              this.isDistanceStartTime = false;
              this.countDownTime = (new Date(this.showactivitytimeobj.fEndTime).getTime() -
                new Date().getTime())
            }

          } else {
            this.isshowhuodong = true
            this.showactivityobj = res.obj.couponInfo
            // 大型活动
            if (new Date().getTime() >= new Date(res.obj.couponInfo.fRemindTime).getTime() &&
              new Date()
                .getTime() < new Date(
                  res.obj.couponInfo.fBeginTime).getTime()) {
              console.log(new Date(res.obj.couponInfo.fBeginTime).getTime() - new Date()
                .getTime())
              this.showhuodongtext = "优惠券距开始:"
              this.countDownTimeactivity = (new Date(res.obj.couponInfo.fBeginTime).getTime() -
                new Date()
                  .getTime())
            }
            if (new Date().getTime() >= new Date(res.obj.couponInfo.fBeginTime).getTime() &&
              new Date()
                .getTime() < new Date(
                  res.obj.couponInfo.fEndTime).getTime()) {
              console.log(new Date(res.obj.couponInfo.fEndTime).getTime() - new Date().getTime())
              this.showhuodongtext = "优惠券距结束:"
              this.countDownTimeactivity = (new Date(res.obj.couponInfo.fEndTime).getTime() -
                new Date()
                  .getTime())
            }
          }
          // let beforeshow = new Date(res.obj.couponInfo.fRemindTime).getTime(); //活动开始展示时间
          // let start = new Date(res.obj.couponInfo.fBeginTime).getTime(); //活动开始时间
          // let end = new Date(res.obj.couponInfo.fEndTime).getTime(); //活动结束时间
          // let now = new Date().getTime();
        }
      })
        .catch(err => { });
    },
  },
}
</script>
<style lang="scss" scoped>
.assume_bg {
  background-image: url(../../assets/assume.png);
  width: 230px;
  height: 104px;
  .assume_playment{
    margin-top: 9px;
    height: 24px;
    color: #9D5F00;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }
  .assume_card {
    margin-top: 6px;
    height: 24px;
    color: #9D5F00;
    font-size: 20px;
    text-align: center;
    margin-bottom: 6px;
  }
  .assume_button {
    width: 140px;
    height: 26px;
    background: rgb(255, 89, 38);
    border-radius: 5px;
    margin: 0 auto;
    font-size: 18px;
    text-align: center;
    color: #fff;
  }
}
.center_div {
  display: flex;
  justify-content: space-between;
}
.top_bar {
  width: 100%;
  height: 28px;
  // border: 1px solid;
  .top-left_bar {
    width: 237px;
    height: 100%;
    // background: red;
    font-size: 28px;
    font-weight: 700;
    color: #FF6600;
    line-height: 28px;;
  }
  img {
    width: 863px;
    height: 24px;
    margin-top: 2px;
  }
}
/* @import "../assets/css/index.css"; */
.color-theme {
  @include themify($themes) {
    color: themed("themes_color");
  }
}

.overscore {
  max-height: 400px;
  overflow-y: auto;
  min-height: 400px;
}

.margin-t-5 {
  margin-top: 5px;
}

.w100 {
  width: 100px;
}

.bg-color-theme {
  @include themify($themes) {
    background-color: themed("themes_color");
  }
}

.border-fff {
  border-color: #fff !important;
}

.page-box {
  width: 100%;
  background-color: #f8f9fe;
}

.width-1100 {
  width: 1100px;
}

.main-nav {
  width: 100%;
  position: relative;
}

.height-400 {
  height: 400px;
}

.coupon-box-one {
  width: 193px;
  height: 103px;
  box-sizing: border-box;
  justify-content: center;
}

.coupon-box-two {
  width: 200px;
  height: 90px;
  position: relative;
}

.coupon-box-two-r-border {
  width: 1px;
  height: 70px;
  border-right: 1px dashed #ccc;
  position: absolute;
  right: 0;
}



.icon-jiantoux {
  opacity: 0.8;
}

.coupon-bg-red {
  background-color: rgb(255, 56, 5);
}

.coupon-bg-FFCCCC {
  background-color: #ffcccc;
}

.coupon-text-red {
  color: #d60424;
}

.coupon-content-two-top {
  width: 200px;
}

.coupon-content-two-btn {
  width: 75px;
  height: 20px;
  border-radius: 20px;
}

.main-nav-left {
  width: 170px;
  position: relative;
  z-index: 1;
}

.main-nav-left-inner-box {
  width: 170px;
  // overflow-y: scroll;
  overflow-x: hidden;
}

.main-nav-left-item {
  // width: 130px;
  position: relative;
}

.main-nav-left-item:hover {
  @include themify($themes) {
    background-color: themed("themes_color");
  }

  color: #fff;

  &::v-deep {
    .iconfont {
      color: #fff;
    }

    .main-nav-left-item-right-line-before {
      border-color: #fff;
    }
  }
}
.main-nav-left-item:hover .imgBac {
  filter: grayscale(100%) brightness(800%);
}
.main-nav-left-before {
  width: 124px;
  height: 1px;
  border-bottom: 1px dashed #e4e4e4;
  position: absolute;
  top: 0;
  left: calc((170px - 140px) / 2);
}

.main-hover-box {
  width: 690px;
  height: 380px;
  overflow-y: auto;
  border: 1px solid #e4e4e4;
  background-color: #f8f9fe;
  position: absolute;
  top: 0;
  left: 170px;
  z-index: 900;
}

.main-hover-box-title {
  display: inline-block;
  min-width: 25px;
  height: 24px;
  line-height: 24px;
  padding: 0 15px;
}

.main-nav-left-item-height {
  height: 80px;
}

.main-nav-left-item-right-line {
  position: relative;
  margin-right: 14px;
}

.main-nav-left-item-right-line-before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 12px;
  border-right: 1px solid black;
  top: 4px;
  right: -8px;
}

.main-nav-box {
  width: 710px;
}

.main-nav-center::v-deep {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  // 轮播图 左右箭头 影藏  部分
  .el-carousel .el-carousel__arrow {
    // display: none;
  }

  // 轮播图 左箭头 移位置
  .el-carousel__arrow--left {
    left: 10%;
  }

  // 轮播图 右箭头 移位置
  .el-carousel__arrow--right {
    right: 10%;
  }
}

.main-nav-center-carousel {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}

// 轮播图 左右箭头 影藏  全局
// .el-carousel::v-deep .el-carousel__arrow {
// 	display: none;
// }

// 轮播图 下方指示器 影藏  全局
.el-carousel::v-deep .el-carousel__indicators {
  // display: none;
}

.main-nav-right {
  height: 400px;
  position: relative;
  z-index: 1;
}

.main-nav-right-login-box {
  width: 194px;
  height: 78px;
}

.main-nav-right-login-avatar {
  width: 55px;
  height: 55px;
  border-radius: 999px;
  background-color: #d3f2fb;
  overflow: hidden;
}

.main-nav-right-login-content {
  width: 136px;
  height: 35px;
}

.text-decoration-underline {
  text-decoration: underline;
}

.main-nav-right-notice {
  width: 194px;
  height: 113px;
}

.main-nav-right-top {
  height: 95px;
  margin-bottom: -5px;
}

.notice-box {
  height: 40px;
  // margin-left: -5px;
}

.main-nav-right-top-title {
  width: 87px;
  // border: 1px solid #339966;
  line-height: 18px;
  // line-height: 50px;
}

.main-nav-right-model {
  width: 194px;
  height: 115px;
}

.main-nav-right-model-carousel-box {
  margin-bottom: -8px;
}

.main-nav-right-model-carousel::v-deep {
  width: 100%;
  height: 100%;
  padding-top: 5px;

  .el-carousel__indicators {
    display: none;
  }
}

.main-nav-right-bottom-item {
  width: 64.5px;
  height: 44px;
}

.transform-rotate-negative-90 {
  transform: rotate(-90deg);
}

.transform-rotate-positive-90 {
  transform: rotate(90deg);
}

.box-row-carousel-one {
  .el-carousel::v-deep .el-carousel__arrow {
    width: 26px;
    border-radius: 0;
    font-size: 22px;
  }

  .el-carousel::v-deep .el-icon-arrow-left {
    margin-top: 2px;
    margin-left: -3px;
  }

  .el-carousel::v-deep .el-icon-arrow-right {
    margin-top: 2px;
    margin-right: -3px;
  }

  .el-carousel::v-deep .el-carousel__arrow--left {
    left: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .el-carousel::v-deep .el-carousel__arrow--right {
    right: 0;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
}

.box-row-carousel-two {
  .el-carousel::v-deep .el-carousel__arrow {
    width: 26px;
    border-radius: 0;
    font-size: 22px;
  }

  .el-carousel::v-deep .el-icon-arrow-left {
    margin-top: 2px;
    margin-left: -3px;
  }

  .el-carousel::v-deep .el-icon-arrow-right {
    margin-top: 2px;
    margin-right: -3px;
  }

  .el-carousel::v-deep .el-carousel__arrow--left {
    left: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .el-carousel::v-deep .el-carousel__arrow--right {
    right: 0;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
}

.box-one {
  min-width: 1080px;
  max-width: 1080px;
  min-height: 294px;
  padding: 10px;
  position: relative;
}

.box-one-row {
  min-height: 282px;
  overflow-x: auto;
  /* firefox */
  scrollbar-width: none;
  /* IE 10+ */
  -ms-overflow-style: none;
  position: relative;
}

.box-one-row::-webkit-scrollbar {
  /* Chrome Safari */
  display: none;
}

.box-one-left-arrow-position {
  position: absolute;
  left: 208px;
  top: calc((364px - 50px) / 2);
}

.box-one-right-arrow-position {
  position: absolute;
  right: 20px;
  top: calc((364px - 50px) / 2);
}

.box-one-row-item-full-image {
  min-width: 168px;
  height: 260px;
  background-color: #ffe5de;
  position: relative;
}

.box-one-row-item-full-image img {
  width: 100% !important;
  height: 100% !important;
}

.box-one-row-full-image {
  height: 265px;
  position: absolute;
  left: 0;
  top: 0;
}

.box-one-row-carousel {
  width: 883px;
}

.box-one-row-item-box {
  width: 168px;
  height: 260px;
  position: relative;
}

.box-one-row-item {
  width: 168px;
  height: 260px;
  border: 1px solid #f2f2f2;
  position: relative;
}

.box-one-row-buoy {
  min-width: 60px;
  width: 60px;
  height: 60px;
  // background-image: linear-gradient(to right, #ff0000, #ff9999);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  padding: 0 10px;
  line-height: 24px;
}

.box-one-row-buoy1 {
  min-width: 60px;
  width: 60px;
  height: 60px;
  // background-image: linear-gradient(to right, #ff0000, #ff9999);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 0 10px;
  line-height: 24px;
}

.box-one-row-buoy2 {
  min-width: 60px;
  width: 60px;
  height: 60px;
  // background-image: linear-gradient(to right, #ff0000, #ff9999);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  padding: 0 10px;
  line-height: 24px;
}

.box-one-row-buoy3 {
  min-width: 60px;
  width: 60px;
  height: 60px;
  // background-image: linear-gradient(to right, #ff0000, #ff9999);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  padding: 0 10px;
  line-height: 24px;
}

.box-one-row-picture-box {
  width: 168px;
  height: 170px;
}

.box-one-row-picture {
  // width: 144px;
  height: 148px;
}

.box-one-row-content-box {
  width: 148px;
  height: 74px;
  background-color: #ffe5de;
}

.box-one-row-content-title {
  width: 148px;
  color: #990000;
}

.box-one-row-content-price {
  width: 148px;
  color: #ff0000;
}

.box-advert::v-deep {
  height: 90px;
  font-size: 26px;
  font-weight: bold;
  color: #cccccc;

  .el-carousel .el-carousel__indicators {
    display: none;
  }
}

.box-two {
  height: 302px;
  position: relative;
}

.box-two-left-arrow-position {
  position: absolute;
  left: 0;
  top: calc((364px - 50px) / 2);
}

.box-two-right-arrow-position {
  position: absolute;
  right: 0;
  top: calc((364px - 50px) / 2);
}

.box-two-top {
  position: relative;
}

.box-two-top::before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 1032px;
  border-color: #fff3db #fff3db #fff3db #f8f9fe;
  border-width: 0 48px 48px 20px;
  border-style: solid;
}

.box-two-top-content {
  height: 48px;
  padding-left: 30px;
  padding-right: 20px;
  position: relative;
  top: 0;
  left: 0;
}

.box-two-top-slash {
  margin: 0 6px 0 4px;
}

.box-two-row-carousel ::v-deep {
  width: 1100px;

  .el-carousel__indicators {
    display: none;
  }
}

.box-two-row {
  min-height: 245px;
}

.box-two-item-box {
  width: 155px;
  height: 225px;
  background-color: #fff3db;
}

.box-two-picture-box {
  width: 152px;
  height: 152px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
}

.box-two-picture {
  height: 130px;
  width: 130px;
}

.box-two-title {
  width: 152px;
  color: #993300;
  // line-height: 20px;
  margin-top: 6px;
}

.box-two-integral {
  width: 152px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  height: 20px;
  line-height: 20px;
}

.icon-jinbi1 {
  color: #ffcc00;
  margin-right: 10px;
}

// 框架三
.box-three {
  width: 1074px;
  height: 249px;
  padding-bottom: 0;
}
/deep/.el-carousel__indicator el-carousel__indicator--horizontal is-active {
  padding: 0px;
}
.box-three-carousel::v-deep {
  width: 1100px;

  // .el-carousel .el-carousel__indicators {
  // 	// display: none;
  // 	border-radius : 4px;
  // }
  .el-carousel__indicator--horizontal .el-carousel__button {
    width: 10px;
    height: 10px;
    background: transparent;
    // border: 1px solid #e2e2e2;
    border-radius: 50%;
    opacity: 0.5;
  }

  // .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  //   width: 10px;
  //   height: 10px;
  //   background: #cccccc;
  //   border-radius: 50%;
  //   opacity: 1;
  // }

  .el-carousel__indicators--outside {
    position: absolute;
    bottom: 0;
  }
}

.box-three-carousel-item {
  height: 208px;
}

.box-three-carousel-item-shop {
  width: 190px;
  height: 85px;
}

.box-three-carousel-item-shop-title {
  width: 138px;
}

.box-three-carousel-item-shop-img {
  width: 55px;
  height: 55px;
  border-radius: 75px;
}

.box-three-carousel-item-shop-bg-one {
  // background: linear-gradient(150deg, rgba(46, 89, 192, 1) 0%, rgba(46, 89, 192, 1) 0%, rgba(205, 202, 255, 1) 100%, rgba(205, 202, 255, 1) 100%);
  color: #1e1e1e;
  border: 1px solid #f2f2f2;
  // border-right: none;
  padding: 0 12px;
}

.box-three-carousel-item-shop-bg-two {
  // background: linear-gradient(150deg, rgba(224, 39, 53, 1) 0%, rgba(224, 39, 53, 1) 0%, rgba(254, 209, 92, 1) 100%, rgba(254, 209, 92, 1) 100%);
}

.box-three-carousel-item-brand {
  width: 127px;
  height: 72px;
}

.box-three-carousel-item-brand-img-box {
  width: 100%;
  height: 42px;
}

.box-three-carousel-item-brand-img {
  width: 93px;
  height: 36px;
}

.box-three-carousel-item-brand-img-border {
  border-right: 1px solid #e4e4e4;
}

// 框架四
.box-four {
  width: 1060px;
  min-height: 346px;
  height: auto;
}

.box-four-row {
  margin-top: 10px;
}

.box-four-item-box {
  min-width: 1060px;
  min-height: 315px;
  height: auto;
}

.box-four-item {
  width: 202px;
  height: 315px;
}

.box-four-picture-box {
  width: 202px;
  height: 202px;
}

.box-four-picture {
  height: 169px;
}

.box-four-content-title {
  height: 40px;
  line-height: 20px;
}

.box-four-content-price {
  height: 20px;
  line-height: 20px;
  color: #ff0000;
}

.box-four-content-type-box {
  display: flex;
  align-items: center;
}

.box-four-content-type {
  min-width: 20px;
  height: 17px;
  background-image: linear-gradient(to right, #ff0000, #ff9999);
  padding: 0 4px;
  line-height: 17px;
  border-radius: 2px;
  margin-right: 5px;
}

.box-four-content-integral {
  min-width: 20px;
  height: 17px;
  border: 1px solid #ff0000;
  padding: 0 4px;
  line-height: 17px;
  border-radius: 2px;
}

.box-four-content-shop {
  margin-top: 2px;
}

.box-four-content-shop-text {
  width: 130px;
}

.dialogScroll {
  // max-height: 50vh;
  // overflow-y: scroll;
  min-height: 300px;
  max-height: 350px;
  // overflow-x: hidden;
  overflow-y: scroll;
}

.color-339966 {
  color: #339966;
}

.Notice-title {
  color: #4b8254;
  margin-bottom: 10px;
}

.text-right {
  bottom: -65px;
  position: absolute;
  right: 5px;
}

.left-footer {
  line-height: 19px;
}

.notice-pre-btn,
.notice-next-btn {
  width: 40px;
  color: #339966;
  border: 1px solid rgba(51, 153, 102, 1);
  line-height: 19px;
  height: 19px;
  padding: 0 5px;
}

::v-deep .dialogNotice .el-dialog__headerbtn {
  position: absolute;
  top: -20%;
  right: -60%;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #ffffff;
  border: 2px solid #ffffff;
}

::v-deep .dialogNotice .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
}

::v-deep .el-dialog__header {
  padding: 10px 20px 10px;
}

::v-deep .el-dialog__body {
  padding: 0px 20px 20px 20px;
}

::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  color: #508659;
  border: 1px solid #508659;
  line-height: 30px;
  height: 30px;
  margin-left: 10px;
}

.divnum {
  margin-left: 15px;
  line-height: 35px;
  height: 45px;
  color: #508659;
  display: flex;
  margin-bottom: 30px;
}

::v-deep .el-pagination span {
  line-height: 30px;
  height: 30px;
}

::v-deep .el-pagination .btn-prev {
  padding-right: 0;
}

::v-deep .el-pagination .btn-next {
  padding-left: 0;
}

::v-deep .el-pagination button {
  padding: 0;
  text-align: center;
  width: 50px;
}

.body-mask {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.4;
  text-align: center;
  z-index: 98;
}

.close-notice-btn {
  top: 5px;
  position: absolute;
  right: -26px;
  z-index: 201;
}

.newclose-notice-btn {
  top: 10px;
  position: absolute;
  right: 10px;
  z-index: 201;
}

.notice-content {
  width: 900px;
  height: 700px;
  overflow: hidden;
  position: fixed;
  left: 50%;
  top: 55%;
  z-index: 100;
  transform: translate(-50%, -50%);
  // background: url(../assets/notice-bgc.png);
  // background-size: 100% 100%;
}

.notice-content-item {
  // overflow-y: scroll;
  // position: relative;
  // left: 55%;
  // top: 58%;
  // z-index: 200;
  // transform: translate(-58%, -55%);
  width: 100%;
  // height:100%;
  background-color: #ffffff;
}

.notice-item-box {
  padding-top: 30px;
  // height: 381px;
  margin: 30px;
  position: relative;
}

.pegation {
  position: absolute;
  bottom: 0;
}

::v-deep .richtext-box p {
  line-height: 26px;
  text-align: left;
  text-indent: 20px;
}

::v-deep .el-dialog {
  // margin-top: 40vh !important;
}

::v-deep .homeDialog {
  // display: flex !important;
  // align-items: center !important;
  overflow: hidden !important;

  .el-dialog {
    background: transparent;
    box-shadow: none;
    margin: 0px !important;
  }

  .el-dialog__header {
    display: none;
  }

  // .el-dialog__body {
  // 	padding: 30px 20px;
  // }
}

::v-deep .introducePict {
  display: flex;

  .el-dialog {
    background: transparent;
    box-shadow: none;
    margin: 0px auto !important;
    max-width: 60vh;
    max-height: 70vh;
  }

  .el-dialog__body {
    margin: 0 auto;
    max-width: 80%;
  }

  .el-dialog__headerbtn {
    font-size: 38px;
    top: 10px;
    right: 015px;

    .el-dialog__close {
      color: #ffffff;
    }
  }
}

::v-deep .introduceDialog {
  overflow: hidden !important;

  .el-dialog__body {
    padding: 20px 20px 20px 20px;
  }
}

::v-deep .introduceContent {
  .el-dialog {
    box-shadow: none;
    margin: 0px !important;
  }
}

.noticeclos {
  // position: absolute;
  // top: -198px;
  // left: 380px;
}

.active_mini {
  right: -55px;
  top: 5%;
  position: fixed;
  z-index: 1000;
  width: 7%;
}

.active_mini:hover {
  right: 0px;
  top: 5%;
  position: fixed;
  z-index: 1000;
  width: 7%;
}

.box-sellout {
  min-width: 70px;
  width: 70px;
  height: 70px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
  padding: 0 10px;
  line-height: 24px;
}

.closeicon {
  color: #fff;
  font-size: 32px;
  top: -33px;
  position: absolute;
  right: -46px;
}

::v-deep .dialogNotice .el-dialog__headerbtn {
  position: absolute;
  top: -100%;
  right: -6%;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.text-el {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 138px;
  color: #a1a1a1;
}
.newLaR {
  border: 1px solid #2979ff;
  display: flex;
  line-height: 21px;
  border-radius: 12px;
  justify-content: center;
  margin-top: 3px;
  width: 108px;
}
</style>

.box-one {
  min-width: 1080px;
  max-width: 1080px;
  min-height: 294px;
  padding: 10px;
  position: relative;
}

.box-one-row {
  min-height: 282px;
  overflow-x: auto;
  /* firefox */
  scrollbar-width: none;
  /* IE 10+ */
  -ms-overflow-style: none;
  position: relative;
}

.box-one-row::-webkit-scrollbar {
  /* Chrome Safari */
  display: none;
}

.box-one-left-arrow-position {
  position: absolute;
  left: 208px;
  top: calc((364px - 50px) / 2);
}

.box-one-right-arrow-position {
  position: absolute;
  right: 20px;
  top: calc((364px - 50px) / 2);
}

.box-one-row-item-full-image {
  min-width: 168px;
  height: 260px;
  background-color: #ffe5de;
  position: relative;
}

.box-one-row-item-full-image img {
  width: 100% !important;
  height: 100% !important;
}

.box-one-row-full-image {
  height: 265px;
  position: absolute;
  left: 0;
  top: 0;
}

.box-one-row-carousel {
  width: 883px;
}

.box-one-row-item-box {
  width: 168px;
  height: 260px;
  position: relative;
}

.box-one-row-item {
  width: 168px;
  height: 260px;
  border: 1px solid #f2f2f2;
  position: relative;
}

.box-one-row-buoy {
  min-width: 60px;
  width: 60px;
  height: 60px;
  // background-image: linear-gradient(to right, #ff0000, #ff9999);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  padding: 0 10px;
  line-height: 24px;
}

.box-one-row-buoy1 {
  min-width: 60px;
  width: 60px;
  height: 60px;
  // background-image: linear-gradient(to right, #ff0000, #ff9999);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 0 10px;
  line-height: 24px;
}

.box-one-row-buoy2 {
  min-width: 60px;
  width: 60px;
  height: 60px;
  // background-image: linear-gradient(to right, #ff0000, #ff9999);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  padding: 0 10px;
  line-height: 24px;
}

.box-one-row-buoy3 {
  min-width: 60px;
  width: 60px;
  height: 60px;
  // background-image: linear-gradient(to right, #ff0000, #ff9999);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  padding: 0 10px;
  line-height: 24px;
}

.box-one-row-picture-box {
  width: 168px;
  height: 170px;
}

.box-one-row-picture {
  // width: 144px;
  height: 148px;
}

.box-one-row-content-box {
  width: 148px;
  height: 74px;
  background-color: #ffe5de;
}

.box-one-row-content-title {
  width: 148px;
  color: #990000;
}

.box-one-row-content-price {
  width: 148px;
  color: #ff0000;
}
</style>