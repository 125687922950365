<template>
	<!-- 店铺规则 -->
	<div :class="themeClass">
		<!-- <div class="ShopRules-bg">
			<div class="shopRules-con">
				<p class="color-theme font-size14 margin-l-13 padding-t-13 font-weight700 margin-b-10">店铺规则</p>
				<div class="shops-box">
					<div class="shops-con">
						<div class="font-size12" v-for="(item,index) of shopRulesData" :key="index">
							<div class="shopsCon-title font-color-fff font-weight700">{{index+1}}、{{item.title}}</div>
							<p class="shopsCon-titleOne">{{item.rules}}</p>
							<p class="threeline-show-hide">
								{{item.rule}}
							</p>
							<p class="shopsCon-titleOne">{{item.titles}}</p>
							<p>{{item.titlesw}}</p>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="flex-colum border-F2F2F2 margin-b-10 padding-13 ShopRules-bg">
			<div class="bg-fff padding-13 radius10">
				<div class="font-weight700 margin-b-10 color-theme" style="">店铺规则</div>
				<div class="">
					<!--  style="height: 200px;overflow-y:scroll;" -->
					<div v-for="(item,index) of ruledetails" :key="index">
						<div class="flex-colum">
							<div v-if="item.fIsTitle==2" class="color-theme font-weight700" style="padding-left: 10px; width: 130px;height: 30px; line-height: 30px; background: linear-gradient(to right, #017DFA 0%, #01B9E5 50%, #00F0D1 100%);color: #FFFFFF;">
								{{item.fValue}}
							</div>
							<div v-if="item.fIsTitle==1" class="font-size12 font-weight700" style="line-height: 18px;color: orange;">
								{{item.fValue}}
							</div>
							<div v-if="item.fIsTitle==0" class="font-size12" style="line-height: 18px;">
								{{item.fValue}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		props: {
			ruledetails: {
				type: Array,
				default: []
			}
		},
		data() {
			return {

			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {},
		methods: {

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.backgroundcolor-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.shops-box {
		width: 698rpx;
		// height: 640rpx;
		// background: linear-gradient(to top, #AEEBFF 0%, #AEEBFF 50%, #069CFF 100%);
		border-radius: 10rpx;
		margin: 0 auto;
		margin-top: 22rpx;
		// margin-bottom: 22rpx;
		padding-bottom: 22rpx;
	}

	.shopsCon-title {
		width: 130px;
		height: 24px;
		background: linear-gradient(to right, #017DFA 0%, #01B9E5 50%, #00F0D1 100%);
		// text-align: center;
		line-height: 24px;
	}

	.shopsCon-titleOne {
		font-weight: 700;
		color: #FF9900;
	}

	// .aaa p{
	// 	font-size: 12px;
	// 	    color: #666666;
	// 	    line-height: 22px;
	// }
	.shops-con p {
		margin-left: 20px;
		line-height: 22px;
	}

	// .shops-con {
	// 	// width: 323px;
	// 	// height: 267px;
	// 	opacity: 0.9;
	// 	border-radius: 5px;
	// 	box-shadow: 2rpx 2rpx 10rpx 0px rgba(0, 0, 0, 0.10);
	// 	margin: 0rpx 22rpx;
	// 	background-color: #F1FAFF;
	// 	border-top: 1px solid transparent;
	// }

	// .shops-con p {
	// 	display: block;
	// 	width: 260rpx;
	// 	height: 48rpx;
	// 	background: linear-gradient(to right, #017DFA 0%, #01B9E5 50%, #00F0D1 100%);
	// 	font-size: 24rpx;
	// 	font-weight: bold;
	// 	text-align: left;
	// 	padding-left: 40rpx;
	// 	color: #ffffff;
	// 	box-shadow: 2rpx 6rpx 10rpx 0px #BDD7DB;
	// 	line-height: 48rpx;
	// 	margin-left: -6rpx;
	// 	margin-top: 12rpx;
	// }

	// .shops-con p {
	// 	font-size: 24rpx;
	// 	font-weight: 400;
	// 	padding: 12rpx 0px 12rpx 80rpx;
	// }

	.shops-con1 {
		width: 93%;
		height: 484rpx;
		background: #fea686;
		border-radius: 20rpx;
		margin: 0 auto;
		margin-top: 22rpx;
	}

	.ShopRules-bg {
		max-width: 1074px;
		height: auto;
		background: inherit;
		background: #f8f9fe;
		// background-color: rgba(248, 249, 254, 1);
		border: none;
		margin: 0 auto;
		margin-bottom: 30px;
	}

	.shopRules-con {
		max-width: 1074px;
		height: 620px;
		background: inherit;
		background-color: rgba(255, 255, 255, 1);
		margin: 0px auto;
		// padding:13px 13px;
	}

	.shopRules-con p {}
</style>
