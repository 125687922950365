<template>
	<div :class="themeClass" id="content">
		<el-container>
			<Top></Top>
		</el-container>
		<div class="headall">
			<div class="head ">
				<div class="head-1">
					<router-link to="/"><img :src="logoUrl" style="height: 58px;width:182px;background-size:cover;"/></router-link>
				</div>
				<div class="so marato search-box">
					<el-input placeholder="请输入搜索内容" style="font-size: 15px;text-align: left;line-height: 15px;"
						v-model="search" @keyup.native.enter="searchClick">
						<!-- <el-button slot="append" @click="searchClick">搜索</el-button> -->
						<div class="search-button pointer font-color-fff font-size15" slot="append"
							@click="searchClick">搜索</div>
						<!-- <searchAll ></searchAll> -->
					</el-input>
					<!-- <searchAll ></searchAll> -->
				</div>
				<!-- <div class="so marato ">
					<el-input placeholder="请输入搜索内容" v-model="search" >
						<div class=" search-button pointer font-color-fff font-size13" slot="append" @click="searchClick">搜聚材通</div>
					
					</el-input>
				</div> -->
				<!-- <div class="so mar20"></div> -->
			</div>
		</div>
		<div class="shopheard">
			<div class="shopheard-d1">
				<!-- <img src="../src/assets/u1347.jpg" alt=""> -->
				<div class="hp-swiper-box">
					<div class="hp-card-column-item-box flex-row-start-center">
						<div class="hp-card-column-picture-box flex-row-center-center">
							<img class="hp-card-column-picture"
								:src="shop.fShopLogoPath == '' ? imgurl : shop.fShopLogoPath" />
						</div>

						<div class="hp-card-column-content flex-colum-between">
							<div class="fl flex-row-align-center">
								<div class="hp-card-column-content-title hide-text font-weight700">{{ shop.fShopName }}
								</div>
								<div class="shol" v-if="shop.fShopTypeID == 1">{{ shop.fShopTypeName }}</div>
							</div>

							<div class="hp-card-column-content-other">
								<div
									class=" font-weight700 hp-card-column-content-other-attention font-size12 font-color-fff">
									好评率 {{ shop.fEvaluationScore }}%</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="main-nav-center height-400" v-if="AdList.length > 0">
				<el-carousel height="400px">
					<el-carousel-item v-for="(item, index) in AdList" :key="'carousel' + index"><img
							class="main-nav-center-carousel" :src="item.fPath" /></el-carousel-item>
				</el-carousel>
			</div>


			<!-- 	公告通知box -->
			<el-dialog :visible.sync="noticeShow" width="10%" @close="closeNoticeShow" :close-on-click-modal="false"
				:close-on-press-escape="false" class="homeDialog">

				<div class="notice-content">
					<div class="notice-content-item">
						<div v-for="(item, index) in DictData.slice((dictCurrentPage-1)*dictPageSize,dictCurrentPage*dictPageSize)"
							:key="index" class="notice-item-box">
							<div class="close-notice-btn pointer noticeclos" @click="closeNoticeShow"><i
			 					class="el-icon-circle-close font-color-000 font-size30"></i></div>
							<!-- <img :src="item.fContentPathUrl" alt=""> -->
							<div class=" Notice-title font-weight700 font-size20">{{ item.fTitle }}</div>
							<div class="margin-b-15 overscore">
								<RichText :html="item.fDesc" />
							</div>
							<div class="flex-row-space-between font-size12 " style="display: block;"
								v-if="dictTotal > 1">
								<div class="text-right">
									<div class="margin-tb-8">{{item.fShopName}}</div>
									<div>{{ item.fCreateTime }}</div>
								</div>
							</div>
							<div class="flex-row-space-between font-size12 " style="display: block;" v-else>
								<div class="text-right" style="bottom: -50px;">
									<div class="margin-tb-8">{{item.fShopName}}</div>
									<div>{{ item.fCreateTime }}</div>
								</div>
							</div>
						</div>
						<div class="divnum">
							<div v-if="dictTotal > 1" class="divnum">
								<div>{{dictCurrentPage}}/{{dictTotal}}</div>
								<el-pagination class="fy" layout=" prev, next" :current-page.sync="dictCurrentPage"
									:total="dictTotal" :page-size.sync="dictPageSize" prev-text="上一页" next-text="下一页">
								</el-pagination>
			 			</div>

						</div>
			  </div>
				</div>
			</el-dialog>






			<!-- 已认证 -->
			<div class="shopheard-d2">
				<div class="shopb flex-row-center-center">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="推荐" name="first" :style="AdList.length > 0 ? 'margin-top: 412px;' : ''">
							<RecommendList :shopData="shop" :foucsData="focusData" @focusChange="Focus"
								:hotShopList="hotShopList" @changeTab="changeTab" @pageChange="pageChange"
								:recommendShopList="recommendShopList" :totalCount="totalCount"
								:Recommendations="Recommendations" :huodongList="huodongList" :page="page" :fShopUnitID="fShopUnitID"
								:AdList="AdList" :ShopProfileList='ShopProfileList' :pagesize="pagesize" :ruledetails="ruledetails" />
						</el-tab-pane>
						<el-tab-pane label="全部商品" :style="AdList.length > 0 ? 'margin-top: 412px;' : ''">
							<CommodityList @pageChange="pageChange" :recommendShopList="recommendShopListall"
								:pagesize="pagesizeall" @changeTab="changeTaball" :page="pageall"
								:totalCount="totalCountall" :Recommendations="Recommendations" :shopData="shop"
								:hotShopList="hotShopList" @pageChangeall='pagechangeall' />
						</el-tab-pane>
						<el-tab-pane v-if="getUserInfo != null && getUserInfo.fCommerceTypeID == 2" label="企业购"
							name="second" :style="AdList.length > 0 ? 'margin-top: 412px;' : ''"></el-tab-pane>
						<el-tab-pane v-if="ispeis != false" label="抽奖" name="third"
							:style="AdList.length>0?'margin-top: 412px;':''">

						</el-tab-pane>
						<el-tab-pane label="任务" name="four" :style="AdList.length > 0 ? 'margin-top: 412px;' : ''">
							<Task v-if="aaa" :shoptype="'2'" :shopId="$route.query.id" />
						</el-tab-pane>
						<el-tab-pane label="店铺简介" name="five" :style="AdList.length > 0 ? 'margin-top: 412px;' : ''">
							<Introduction :Introductions="Introductions" :Qualification="Qualification" :AdList="AdList"
								:ShopProfileList='ShopProfileList' :shopInformation='shop' :shopID='fShopUnitID' />
						</el-tab-pane>
						<el-tab-pane label="店铺规则" name="six" :style="AdList.length > 0 ? 'margin-top: 412px;' : ''">
							<ShopRules v-if="showRules" :shopId="$route.query.id" :ismakeIntegral="ismakeIntegral"
								:ruledetails="ruledetails" />
						</el-tab-pane>
						<!-- <el-tab-pane label="店铺简介" name="seven">
							<Introduction />
						</el-tab-pane> -->
						<!-- <el-tab-pane label="商品" name="second">
							<CommodityList />
						</el-tab-pane> -->
					</el-tabs>
				</div>
			</div>


			<div class="StoreList-footer">
				<Footer />
			</div>
		</div>
		<Suspension></Suspension>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import RecommendList from '@/components/store/RecommendList';
	import CommodityList from '@/components/store/CommodityList';
	import Introduction from '@/components/store/Introduction';
	import ShopRules from '@/components/store/ShopRules';
	import Footer from '@/components/loyout/footer';
	import RichText from '@/components/richtext/RichText'
	import pagination from '@/components/pagination/pagination.vue';
	import Task from '@/views/HomePage/Task/Task.vue';
	 import SERVER from  '../config/config.json';
	export default {
		components: {
			RecommendList,
			CommodityList,
			Introduction,
			Footer,
			ShopRules,
			pagination,
			Task,
			RichText,
		},
		name: 'StoreList',
		data() {
			return {
				imgurl: require('@/assets/shoplogo.png'),
				aaa: false,
				showRules: false,
				huodongList: [],
				Recommendations: [],
				sortType: 1, //1,综合,2,销量,3,价格
				fSortMode: 1, //1正序2倒序
				activeName: 'first',
				search: '',
				currentPage: 1, //当前页
				isisFocus: true,
				starLevel: null,
				shop: {},
				focusData: {},
				fShopUnitID: -1,
				hotShopList: [],
				recommendShopList: [],
				recommendShopListall: [],
				logoUrl: '',
				page: 1,
				totalCount: 0, //总条数
				pagesize: 8, //每页显示条目个数
				Qualification: [],
				Introductions: {},
				ShopProfileList: [], //店铺简介
				className: 'api/mall/ebshop/baseinfo/get-sort-by-weight-list',
				AdList: [], //广告轮播图
				ispeis: false, //是否有抽奖活动
				// 全部商品数据
				pageall: 1,
				totalCountall: 0, //总条数
				pagesizeall: 10, //每页显示条目个数

				noticeShow: false, //公告弹层
				DictData: [],
				dictTotal: 0,
				dictCurrentPage: 1,
				dictPageSize: 1,

				ismakeIntegral: '', //店铺产不产生积分
				ruledetails: [],
				adcodeStr:"",
				// shopRulesData: [{
				// 		title: '一、积分规则',
				// 		outerList: [{
				// 				listTitle: '积分获取：',
				// 				list: [{
				// 						rule: '首次关注店铺送X积分；'
				// 					},
				// 					{
				// 						rule: '任务送积分（详情见任务集市）；'
				// 					},
				// 					// {
				// 					// 	rule: '每日首次订单分享送X积分；'
				// 					// },
				// 					// {
				// 					// 	rule: '每日首次任务分享送X积分；'
				// 					// },
				// 					{
				// 						rule: '每笔订单评价送X积分；'
				// 					},
				// 					{
				// 						rule: '提货出厂送积分，该笔提货对应的货款金额每满100元送1积分（如有积分活动，详见店铺通知）；'
				// 					},
				// 					{
				// 						rule: '其它赠送积分。'
				// 					}
				// 				]
				// 			},
				// 			{
				// 				listTitle: '积分使用：',
				// 				list: [{
				// 						rule: '购买本店铺商品使用；'
				// 					},
				// 					{
				// 						rule: '兑换平台金币使用；'
				// 					},
				// 					{
				// 						rule: '店铺其它活动使用。'
				// 					}
				// 				]
				// 			}
				// 		]
				// 	},
				// 	{
				// 		title: '二、订单有效期',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 				rule: '本店铺订单有效期为自生效之日起X个自然日。'
				// 			}]
				// 		}]
				// 	},
				// 	{
				// 		title: '三、发票',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 					rule: '本店铺每月扎账时间为XX日XX点；'
				// 				},
				// 				{
				// 					rule: '本店铺发票开具时间为每月XX号统一开具；'
				// 				},
				// 				{
				// 					rule: '发票递送方式：⑴、客户自取。⑵、发票开具后X个自然日内邮寄。⑶、电子发票开具后发送至预留邮箱。'
				// 				}
				// 			]
				// 		}]
				// 	},
				// 	{
				// 		title: '四、检验报告单',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 					rule: '本店铺提供：电子检验报告单/纸质检验报告单；'
				// 				},
				// 				{
				// 					rule: '电子检验报告单由会员在本平台自行申请获取；'
				// 				},
				// 				{
				// 					rule: '纸质检验报告单需会员与本店铺客服联系获取。'
				// 				}
				// 			]
				// 		}]
				// 	},
				// 	{
				// 		title: '五、售后',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 					rule: '售后服务电话：XXXX-XXXXXXX/1XXXXXXXXXX'
				// 				},
				// 				{
				// 					rule: '售后服务时间：工作日/每日  XX:XX-XX:XX'
				// 				}
				// 			]
				// 		}]
				// 	}
				// ] //店铺规则
				// SeckkillData:[],
				// countDownTime: null, //倒计时时间
				// showhuodongtext:""
			};
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo', "getLogoUrl"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		created() {

			//          sessionStorage.setItem("fShopUnitID",this.$route.query.id)
			// console.log(this.$route.query.id,"this.$route.query.id")
		},
		mounted() {
			this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
			this.fShopUnitID = this.$route.query.id;
			sessionStorage.setItem('fShopUnitID', this.$route.query.id);
			// console.log(this.$route.query.id,"this.$route.query.id",this.fShopUnitID)
			this.getShopHomepageAdList();
			this.getShopData();
			// this.getGoods();
			// this.getGoodsall();
			// this.getHotGoods();
			// this.getRecommendation();
			this.queryIntegralList();
			this.EnterpriseQualification();

			this.getIsmakeIntegral(); //获取本店铺是否产生积分
			// this.getFirstFocus(); //获取首次关注店铺送多少积分
			// this.getFristOrderShard(); //获取每日首次订单分享送多少积分
			// this.getEverydayFristShard(); //获取每日首次任务分享送多少积分
			// this.getEverydayEvaluation(); //获取每笔订单评价送多少积分
			this.getIntegral(); //获取首次关注店铺送多少积分/获取每笔订单评价送多少积分
			this.getTheNaturalDay(); //获取本店铺订单有效期
			this.getShopSettleDay(); //获取店铺扎帐日
			this.getShopMakeOpenBill(); //获取本店铺发票开具时间为每月多少号
			this.getShopMakeOpenBillPostday(); //获取店铺发票开具后多少个自然日内邮寄
			this.getAfterSalesserviceTelephone(); //获取售后服务电话
			this.getAfterSalesserviceTime(); //获取售后服务时间
			this.ShopProfile(); //获取店铺简介
			this.getispris();
			this.storeRules();
			this.DialogNotice();//获取弹窗公告
			this.acquireaCode()
			// this.getSeckkillList(); //秒杀活动
		},
		methods: {
			// changeEnd(){
			// 	this.getSeckkillList()
			// },
	  //       getSeckkillList() {
			// 	this.ApiRequestPost('/api/mall/ebactivity/seckill-bill-goods-price/get-seckkill-goods-by-fshopid', {
			// 		fShopID:this.fShopUnitID,
			// 		fAppType: 1,
			// 		skipCount: 0,
			// 		maxResultCount: 3,
			// 		filter: "",
			// 		sorting: ""	
			// 	}).then(
			// 		result => {
			// 		   this.SeckkillData=result.obj.items;
			// 		   console.log(this.SeckkillData)
			// 		   let start = new Date(this.SeckkillData[0].fSeckillBillGoodsPrice.fBeginTime).getTime(); //活动开始时间
			//            let end = new Date(this.SeckkillData[0].fSeckillBillGoodsPrice.fEndTime).getTime(); //活动结束时间
			// 		   let now = new Date().getTime();
			// 		   if (now > end) {
			// 		   	console.log('活动已结束')
			// 		   	this.$router.back(-1)
			// 		   	this.showcountdown = false
			// 		   }
			// 		   if (now >= start && now < end) {
			// 		   	this.showhuodongtext = "距结束："
			// 		   	this.countDownTime = parseInt((end - now))
			// 		   	console.log('距离活动结束还剩多少时间==》》已开始活动', this.countDownTime)
			// 		   }
			// 		},
			// 		error => {
			// 		}
			// 	);
			// },
			acquireaCode(){
				if(sessionStorage.getItem("ipAdcode") == null){
					fetch('https://restapi.amap.com/v3/ip?key=' + SERVER.gaodeMapKey,{
						method:'get'
					}).then(async  (response)=>{
						let res  = await  response.json()
						if(res.adcode != undefined){
							this.adcodeStr=res.adcode
							this.getHotGoods(res.adcode)
							this.getRecommendation(res.adcode)
							this.getGoods();
							this.getGoodsall();
						}else{
							let resadcode = ""
							this.adcodeStr=resadcode
							this.getHotGoods(resadcode)
							this.getRecommendation(resadcode)
							this.getGoods();
							this.getGoodsall();
						}
						sessionStorage.setItem("ipAdcode",res.adcode)
					},(error)=>{
						sessionStorage.setItem("ipAdcode",undefined)
						let resadcode = ""
						this.adcodeStr=resadcode
						this.getHotGoods(resadcode)
						this.getRecommendation(resadcode)
						this.getGoods();
						this.getGoodsall();
					})
				}else{
					if(sessionStorage.getItem("ipAdcode") == undefined){
						let resadcode = ""
						this.adcodeStr=resadcode
						this.getHotGoods(resadcode)
						this.getRecommendation(resadcode)
						this.getGoods();
						this.getGoodsall();
					}else{
						this.adcodeStr=sessionStorage.getItem("ipAdcode")
						this.getHotGoods(sessionStorage.getItem("ipAdcode"))
						this.getRecommendation(sessionStorage.getItem("ipAdcode"))
						this.getGoods();
						this.getGoodsall();
					}
				}
			},
			//公告分页点击事件
			handleCurrentChange(val) {
			  this.Noticepage = val;
			  // this.getNoticeList();
			},
			//公告下一页事件
			nextBtn() {
			  this.DialogUpdate();
			  this.$set('this.NoticeData', 'this.Noticepage', 'this.NoticeDatas');
			  this.Noticepage = this.Noticepage + 1;
			  // console.log(this.Noticepage,"this.Noticepage")
			  // this.DialogUpdate()
			},
			//公告上一页事件
			prvtBtn() {
			  this.Noticepage = this.Noticepage - 1;
			},
			//获取弹窗公告
			DialogNotice() {
				console.log('this.fShopUnitID',this.fShopUnitID)
				// if(this.getUserInfo!=null){
				// if(this.getUserInfo.fVerifiedStatus){
				this.ApiRequestPostNOMess('api/mall/ebbase/announcement-notice/get-enable-by-shopID',{
					id:this.fShopUnitID
				}).then(
					result => {
						console.log(result.obj)
						this.NoticeData = result.obj;
						this.DictData = result.obj;
						console.log(this.DictData,'this.DictData')
						this.dictTotal = this.NoticeData.length;
						console.log('this.dictTotal',this.dictTotal)
						console.log('sessionStorage.getItem(this.getUserInfo.adminPhone)',sessionStorage.getItem(this.getUserInfo.adminPhone))
						if (this.NoticeData.length > 0 && sessionStorage.getItem(this.getUserInfo.adminPhone) ==
							null) {
							this.noticeShow = true;
							sessionStorage.setItem(this.getUserInfo.adminPhone, '1');
						}
						for (let item of result.obj) {
							item.fCreateTime = this.getDate(item.fCreateTime);
						}




					},
					rej => {

					}
				);
				// }
				// }

			},

			//公告关闭按钮点击事件
			closeNoticeShow() {
				this.noticeShow = false;
			},

			//获取是否有抽奖活动
			async getispris() {
				let res = await this.ApiRequestPostNOMess(
					'api/mall/ebactivity/luck-draw-award-pool/get-list-by-fshopID', {
						FShopID: this.fShopUnitID
					})
				if (res.obj == null || res.obj == undefined || res.obj == [] || res.obj.length == 0) {
					this.ispeis = false
				} else {
					this.ispeis = true
				}
			},
			//店铺规则开始
			storeRules() {
				this.ApiRequestPostNOMess('api/mall/ebshop/baseinfo/get-rule-by-shopid', {
					id: this.fShopUnitID
				}).then(
					res => {
						this.ruledetails = res.obj
						// console.log(777777777777,this.rule)
					}
				)
			},
			//获取本店铺是否产生积分
			getIsmakeIntegral() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-ismake-integral', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						console.log('获取本店铺是否产生积分', res);
						this.ismakeIntegral = res ? '产生' : '不产生';
					},
					error => {}
				);
			},
			//获取首次关注店铺送多少积分/获取每笔订单评价送多少积分
			getIntegral() {
				this.ApiRequestPostNOMess('api/mall/ebactivity/first-focus-shop-rule/get-shop-rule', {
					fShopID: this.fShopUnitID
				}).then(res => {
					console.log('获取首次关注店铺送多少积分', res.obj.firstFocusShopRule);
					console.log('获取每笔订单评价送多少积分', res.obj.fAppraiseOrderShopRule);
					// this.shopRulesData[0].outerList[0].list[0].rule = '首次关注店铺送' + res.obj.firstFocusShopRule + '积分；';
					// this.shopRulesData[0].outerList[0].list[2].rule = '每笔订单评价送' + res.obj.fAppraiseOrderShopRule + '积分；';
				}, error => {

				});
			},
			//获取首次关注店铺送多少积分
			// getFirstFocus() {
			// 	this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-first-focus', {
			// 		fShopID: this.fShopUnitID
			// 	}).then(
			// 		res => {
			// 			console.log('获取首次关注店铺送多少积分', res);
			// 			this.shopRulesData[0].outerList[0].list[0].rule = '首次关注店铺送' + res + '积分；';
			// 		},
			// 		error => {}
			// 	);
			// },
			//获取每日首次订单分享送多少积分
			getFristOrderShard() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-frist-order-shard', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						console.log('获取每日首次订单分享送多少积分', res);
						// this.shopRulesData[0].outerList[0].list[2].rule = '每日首次订单分享送' + res + '积分；';
					},
					error => {}
				);
			},
			//获取每日首次任务分享送多少积分
			getEverydayFristShard() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-everyday-first-shard', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						console.log('获取每日首次任务分享送多少积分', res);
						// this.shopRulesData[0].outerList[0].list[3].rule = '每日首次任务分享送' + res + '积分；';
					},
					error => {}
				);
			},
			//获取每笔订单评价送多少积分
			// getEverydayEvaluation() {
			// 	this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-everyorder-evaluation', {
			// 		fShopID: this.fShopUnitID
			// 	}).then(
			// 		res => {
			// 			console.log('获取每笔订单评价送多少积分', res);
			// 			this.shopRulesData[0].outerList[0].list[2].rule = '每笔订单评价送' + res + '积分；';
			// 		},
			// 		error => {}
			// 	);
			// },
			//获取本店铺订单有效期
			getTheNaturalDay() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-the-natural-day', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						console.log('获取本店铺订单有效期', res);
						// this.shopRulesData[1].outerList[0].list[0].rule = '本店铺订单有效期为自生效之日起' + res + '个自然日。';
					},
					error => {}
				);
			},
			//获取店铺扎帐日
			getShopSettleDay() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-settle-day', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						console.log('获取店铺扎帐日', res);
						this.getShopSettleTime(res);
					},
					error => {}
				);
			},
			//获取店铺扎帐时间
			getShopSettleTime(day) {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-settle-time', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						console.log('获取店铺扎帐时间', res);
						// this.shopRulesData[2].outerList[0].list[0].rule = '本店铺每月扎账时间为' + day + '日' + res + '点；';
					},
					error => {}
				);
			},
			//获取本店铺发票开具时间为每月多少号
			getShopMakeOpenBill() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-make-open-bill', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						console.log('获取本店铺发票开具时间为每月多少号', res);
						// this.shopRulesData[2].outerList[0].list[1].rule = '本店铺发票开具时间为每月' + res + '号统一开具；';
					},
					error => {}
				);
			},
			//获取店铺发票开具后多少个自然日内邮寄
			getShopMakeOpenBillPostday() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-make-open-bill-postday', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						console.log('获取店铺发票开具后多少个自然日内邮寄', res);
						// this.shopRulesData[2].outerList[0].list[2].rule = '发票递送方式：⑴、客户自取。⑵、发票开具后' + res + '个自然日内邮寄。⑶、电子发票开具后发送至预留邮箱。';
					},
					error => {}
				);
			},
			//获取售后服务电话
			getAfterSalesserviceTelephone() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-after-salesservice-telephone', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						console.log('获取售后服务电话', res);
						// this.shopRulesData[4].outerList[0].list[0].rule = '售后服务电话：' + res;
					},
					error => {}
				);
			},
			//获取售后服务时间
			getAfterSalesserviceTime() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-after-salesservice-time', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						console.log('获取售后服务时间', res);
						// this.shopRulesData[4].outerList[0].list[1].rule = '售后服务时间：工作日/每日  ' + res;
					},
					error => {}
				);
			},
			//店铺规则结束

			//获取公告轮播图
			getShopHomepageAdList() {
				this.ApiRequestPost('api/mall/ebshop/ebadvertisement/get-shop-homepage-ad-list', {
					fAppTypeID: this.fAppTypeID,
					fShopUnitID: this.fShopUnitID
				}).then(
					res => {
						// console.log('获取公告轮播图',res);
						this.AdList = res.obj;
					},
					error => {}
				);
			},
			// 获取限时活动店铺
			queryIntegralList() {
				this.ApiRequestPost('/api/mall/ebactivity/point-swap-goods-rule-bill/get-by-shopid', {
					id: this.fShopUnitID
				}).then(
					result => {
						this.isShow = result.obj.isShow;
						if (this.isShow == 1) {
							this.ApiRequestPost(
								'/api/mall/ebactivity/point-swap-goods-price/get-point-swap-goods-by-fshopid', {
									fAppType: 1,
									fShopID: this.fShopUnitID,
									skipCount: 0,
									maxResultCount: 3
								}).then(res => {
								console.log('res限时活动', res);
								// debugger
								this.huodongList = res.obj.items;
								// console.log(this.huodongList,"this.huodongList")
							});
						}
					},
					error => {
						//   alert(JSON.stringify(error));
					}
				);
			},
			//根据店铺id显示店铺的信息
			getShopData() {
				this.ApiRequestPost('api/mall/ebshop/baseinfo/get', {
					id: this.fShopUnitID
				}).then(
					res => {
						console.log(res, "根据店铺id显示店铺的信息")
						// console.log('getShopData: ' + JSON.stringify(res));
						// this.shop = res.obj;
						res.obj.fBeginDate = this.getDate(res.obj.fBeginDate);
						// res.obj.fShopLogoPath = this.getUploadFileURL(res.obj.fShopLogoPath)
						this.$nextTick(() => {
							//店铺Logo
							this.shop = res.obj;
							// console.log(this.shop,"this.shop")
							let sums = res.obj.fEvaluationScore;
							if (sums != null) {
								this.starLevel = sums / 20;
							}
							console.log(this.starLevel, "店铺星级")
							this.Introductions = res.obj;
							console.log('显示店铺的信息', this.Introductions);
						});
						this.getFocus();
					},
					error => {
						// this.$message({
						// 	type: 'success',
						// 	message: 'error.message'
						// });
					}
				);
			},
			async getFocus() {
				let res = await this.ApiRequestPostNOMess('api/mall/auxiliary/focus-shop/get-by-shopunitID', {
					id: this.fShopUnitID
				});
				this.$nextTick(() => {
					this.focusData = res.obj;
				});
				// .then(
				// 	res => {
				// 		// console.log('res: ' + JSON.stringify(res));
				// 		// this.focusData = res.obj;
				// 		this.$nextTick(() => {
				// 			this.focusData = res.obj;
				// 		});
				// 	},
				// 	error => {
				// 		// this.$message({
				// 		// 	type: 'success',
				// 		// 	message: 'error.message'
				// 		// });
				// 	}
				// );
			},
			Focus() {
				// debugger
				// this.$nextTick(()=>{
				// 	this.getFocus()
				// })
				this.getFocus();
				console.log('this.focusData.isFocus', this.focusData.isFocus);
				let urls = '';
				if (this.focusData.isFocus == 1) {
					// 已关注
					urls = 'api/mall/auxiliary/focus-shop/delete';
				} else {
					// 未关注
					urls = 'api/mall/auxiliary/focus-shop/create';
				}
				if (this.isisFocus == true) {
					this.isisFocus = false;
					this.ApiRequestPostNOMess(urls, {
						fShopUnitID: this.fShopUnitID
					}).then(
						res => {
							// console.log('res: ' + JSON.stringify(res));
							this.$nextTick(() => {
								this.getFocus();
							});
							// this.getFocus()
							// setTimeout(() => {
							this.isisFocus = true;
							// }, 10000)
						},
						error => {
							// this.$message({
							// 	type: 'success',
							// 	message: 'error.message'
							// });
						}
					);
				}
			},
			//企业资质
			EnterpriseQualification() {
				this.ApiRequestPost('api/mall/goods/goodsPropertieData/get-factory-by-shopunitID', {
					id: this.fShopUnitID
				}).then(
					res => {
						console.log('企业资质', res.obj);
						this.$nextTick(() => {
							this.Qualification = res.obj;
						});
						this.getFocus();
					},
					error => {
						this.$message({
							type: 'success',
							message: 'error.message'
						});
					}
				);
			},
			//店铺简介
			ShopProfile() {
				this.ApiRequestPost('api/mall/ebshop/introduction/get-list-by-fshopID', {
					fShopID: this.fShopUnitID
				}).then(
					res => {
						this.$nextTick(() => {
							console.log('6666店铺简介', res);
							this.ShopProfileList = res.obj.items
						});
						this.getFocus();
					},
					error => {
						this.$message({
							type: 'success',
							message: 'error.message'
						});
					}
				);
			},
			getHotGoods(val) {
				/* 热销 */
				this.ApiRequestPostNOMess('/api/mall/ebsale/goods-price/get-new-hot-goods-by-fShopUnitID-list', {
				  adcode: val,
				  city: "",
				  flng: 0,
				  flat: 0,
				  id: this.fShopUnitID
				}).then(
					res => {
						// console.log('热销: ' + JSON.stringify(res));
						let data = [];
						for (let item of res.obj) {
							// item.goods.fPicturePath = this.getUploadFileURL(item.goods.fPicturePath);
							// item.goods.fMinGoodsPrice = item.fMinGoodsPrice
							data.push(item.goods);
						}
						this.$nextTick(() => {
							this.hotShopList = res.obj;
							console.log('this.hotShopList热销', this.hotShopList);
						});
					},
					error => {
						this.$message({
							type: 'success',
							message: 'error.message'
						});
					}
				);
			},
			changeTab(id) {
				this.sortType = id;
				this.fSortMode = this.fSortMode == 1 ? 2 : 1;
				this.getGoods();
			},
			changeTaball(id) {
				this.sortType = id;
				this.fSortMode = this.fSortMode == 1 ? 2 : 1;
				this.getGoodsall();
			},
			getGoodsall() {
				/* 获取所有商品 */
				// this.ApiRequestPostNOMess(this.className, {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-new-list-by-shopunitid', {
					fShopUnitID: this.fShopUnitID,
					fAppTypeID: this.fAppTypeID,
					fSortType: this.sortType,
					fSortMode: this.fSortMode,
					adcode:this.adcodeStr,
					city: "",
					flng: 0,
					flat: 0,
					maxResultCount: this.pagesizeall,
					filter: '',
					sorting: '',
					skipCount: (this.pageall - 1) * this.pagesizeall
				}).then(
					res => {
						console.log('res获取所有商品', res);
						this.totalCountall = res.obj.totalCount;
						this.$nextTick(() => {
							this.recommendShopListall = res.obj.items;
						});
					},
					error => {
						this.$message({
							type: 'success',
							message: 'error.message'
						});
					}
				);
			},
			getGoods() {
				/* 获取今日推荐 */
				// this.ApiRequestPostNOMess(this.className, {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-new-list-by-shopunitid', {
					fShopUnitID:this.fShopUnitID,
					fAppTypeID:this.fAppTypeID,
					fSortType: this.sortType,
					fSortMode:this.fSortMode,
					adcode:this.adcodeStr,
					city: "",
					flng: 0,
					flat: 0,
					skipCount:(this.page - 1) * this.pagesize,
					maxResultCount: this.pagesize,
					filter: "",
					sorting: ""
				}).then(
					res => {
						console.log('res获取今日推荐', res);
						this.totalCount = res.obj.totalCount;
						this.$nextTick(() => {
							this.recommendShopList = res.obj.items;
						});
					},
					error => {
						this.$message({
							type: 'success',
							message: 'error.message'
						});
					}
				);
			},
			//店铺推荐
			getRecommendation(val) {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-new-recommend-goods-by-fShopUnitID-list', {
					fShopUnitID: this.fShopUnitID,
					fAppTypeID: this.fAppTypeID,
					adcode: val,
					city: "",
					flng: 0,
					flat: 0,
					skipCount: 0,
					maxResultCount:4,
					filter: "",
					sorting: "",
				}).then(
					res => {
						console.log('res店铺推荐', res);
						// for (let item of res.obj.items) {
						// 	// item.fPicturePath = this.getUploadFileURL(item.fPicturePath);
						// 	// item.goods.fMinGoodsPrice = item.fMinGoodsPrice
						// 	// item = item.goods
						// }
						// this.totalCount = res.obj.totalCount
						this.$nextTick(() => {
							this.Recommendations = res.obj.items;
						});
					},
					error => {
						this.$message({
							type: 'success',
							message: 'error.message'
						});
					}
				);
			},
			pageChange(state) {
				let tempSum = Math.ceil(this.totalCount / this.pagesize);
				if (state == '+') {
					// if (tempSum <= this.page) {
					// 	return;
					// } else {
					// 	this.page == 1;
					// }
					if (this.page < tempSum) {
						this.page++;
						this.getGoods();
					}
				} else {
					// if (this.page <= 1) {
					// 	return;
					// }
					// this.page -= 1;
					if (this.page > 1) {
						this.page--;
						this.getGoods();
					}
				}
			},
			pagechangeall(state) {
				let tempSum = Math.ceil(this.totalCountall / this.pagesizeall);
				if (state == '+') {
					// if (tempSum <= this.page) {
					// 	return;
					// } else {
					// 	this.page == 1;
					// }
					if (this.pageall < tempSum) {
						this.pageall++;
						this.getGoodsall();
					}
				} else {
					// if (this.page <= 1) {
					// 	return;
					// }
					// this.page -= 1;
					if (this.page > 1) {
						this.page--;
						this.getGoods();
					}
				}
			},
			handleClick(tab, event) {

				if (this.ispeis) {
					// 有抽奖活动
					if (this.getUserInfo != null && this.getUserInfo.fCommerceTypeID == 2) {
						console.log('企业')
						if (tab.index == '2') {
							this.$router.push({
								name: 'applicationPricetab',
								query: {
									userId: this.fShopUnitID
								}
							});
						}
						// 3-抽奖
						if (tab.index == '3') {
							this.$router.push({
								name: 'luckMain',
								query: {
									shopId: this.fShopUnitID
								}
							});
						}
						// 4-任务
						if (tab.index == '4') {
							// this.aaa = true;
							this.$router.push({
								name: 'Task',
								query: {
									shoptype: '2',
									shopId: this.fShopUnitID
								}
							});
						}
						if (tab.index == '5') {
							this.showRules = true;
						}
					} else {
						console.log('个人')
						console.log(tab.index)
						// 2-抽奖
						if (tab.index == '2') {

							this.$router.push({
								name: 'luckMain',
								query: {
									shopId: this.fShopUnitID
								}
							});

						}
						// 3-任务
						if (tab.index == '3') {
							this.$router.push({
								name: 'Task',
								query: {
									shoptype: '2',
									shopId: this.fShopUnitID
								}
							});
						}
						if (tab.index == '4') {
							this.showRules = true;
						}
					}


				} else {
					// 没有抽奖活动
					if (this.getUserInfo != null && this.getUserInfo.fCommerceTypeID == 2) {
						console.log('企业')
						if (tab.index == '2') {
							this.$router.push({
								name: 'applicationPricetab',
								query: {
									userId: this.fShopUnitID
								}
							});
						}
						// 3-任务
						if (tab.index == '3') {
							this.$router.push({
								name: 'Task',
								query: {
									shoptype: '2',
									shopId: this.fShopUnitID
								}
							});
						}
						// if (tab.index == '4') {
						// 	// this.aaa = true;
						// 	this.showRules = true;
						// }
						if (tab.index == '5') {
							this.showRules = true;
						}
					} else {
						console.log('个人')
						console.log(tab.index)
						if (tab.index == '2') {
							this.$router.push({
								name: 'Task',
								query: {
									shoptype: '2',
									shopId: this.fShopUnitID
								}
							});
						}
						if (tab.index == '3') {
							this.aaa = true;
						}
						if (tab.index == '4') {
							this.showRules = true;
						}
					}
				}





			},
			searchClick() {}
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.notice-content {
		width: 900px;
		height: 700px;
		overflow: hidden;
		position: fixed;
		left: 50%;
		top: 55%;
		z-index: 100;
		transform: translate(-50%, -50%);
		// background: url(../assets/notice-bgc.png);
		// background-size: 100% 100%;
	}

	.notice-content-item {
		// overflow-y: scroll;
		// position: relative;
		// left: 55%;
		// top: 58%;
		// z-index: 200;
		// transform: translate(-58%, -55%);
		width: 100%;
		// height:100%;
		background-color: #ffffff;
	}

	.notice-item-box {
		padding-top: 30px;
		// height: 381px;
		margin: 30px;
		position: relative;
	}

	.close-notice-btn {
		top: 5px;
		position: absolute;
		right: -26px;
		z-index: 201;
	}

	.overscore {
		max-height: 400px;
		overflow-y: auto;
		min-height: 400px;
	}

	.text-right {
		bottom: -65px;
		position: absolute;
		right: 5px;
	}

	.divnum {
		margin-left: 15px;
		line-height: 35px;
		height: 45px;
		color: #508659;
		display: flex;
		margin-bottom: 30px;
	}

	.Notice-title {
		color: #4b8254;
		margin-bottom: 30px;
	}

	::v-deep .homeDialog {
		// display: flex !important;
		// align-items: center !important;
		overflow: hidden !important;

		.el-dialog {
			background: transparent;
			box-shadow: none;
			margin: 0px !important;
		}

		.el-dialog__header {
			display: none;
		}

		// .el-dialog__body {
		// 	padding: 30px 20px;
		// }
	}

	.bg-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	// .so .el-input-group input {
	// 	height: 32px;
	// 	border: 1px solid #366cfc;
	// 	border-radius: 0;
	// }

	// .marato {
	// 	margin: 20px auto;
	// }

	.search-button {
		// user-select: none;
	}

	.shopheard {
		height: 155px;
		width: 100%;
		background: #0083f5;

		.shopheard-d1 {
			padding-top: 32px;
			height: 123px;
			max-width: 1100px;
			margin: 0 auto;
			background: url(assets/u1347.jpg) no-repeat;

			.fl {
				display: flex;
			}

			.shol {
				padding: 0 2px;
				margin-left: 5px;
				// width: 37px;
				height: 17px;
				background: #ff9900;
				font-size: 12px;
				color: #fff;
				border-radius: 5px;
				text-align: center;
				font-weight: 400;
			}

			.hp-card-column-item-box {
				width: 100%;

				display: -webkit-box;
			}

			.hp-card-column-content-other-attention {
				line-height: 24px;
			}

			.hp-card-column-operation-btn {
				width: 65px;
				height: 24px;
				border: 1px solid #0173fe;
				border-radius: 30px;
				font-weight: 400;
				color: #0173fe;
				text-align: center;
			}

			.hp-card-column-operation-btn2-f {
				width: 65px;
				height: 24px;
				background: #ff0000;
				border: 2rpx solid #ff0000;
				border-radius: 30px;
				font-weight: 400;
				color: #fff;
				text-align: center;
				line-height: 24px;
			}

			.hp-card-column-operation-btn2-n {
				width: 65px;
				height: 24px;
				background: #ffffff;
				border: 2rpx solid #0173fe;
				border-radius: 30px;
				font-weight: 400;
				color: #0173fe;
				text-align: center;
				line-height: 24px;
			}

			.hp-card-column-picture-box img {
				width: 58px;
				height: 58px;
				border-radius: 5px;
				background-color: #fff;
				border: 1px solid #e4e4e4;
			}

			.hp-card-column-content {
				padding: 0 40px 0 20px;
			}

			.hp-card-column-content-title {
				font-size: 18px;
				color: #fff;
				margin-bottom: 10px;
			}

			.hp-card-column-content-other {
				display: flex;
			}

			.hp-card-column-content-other-tab {
				min-width: 37px;
				height: 17px;
				border-radius: 5px;
				background-color: #ff0000;
				padding: 0 8px;
				line-height: 16px;
			}
		}

		.shopheard-d2 ::v-deep {
			background: #3366ff;

			.shopb {
				margin: 0 auto;
				background-color: #eaefff;
				margin-top: -40px;

				.el-tabs {
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}

				.el-tabs__content {
					overflow: hidden;
					position: relative;
				}

				.el-tabs__nav-wrap {
					background: #3366ff;
				}

				.el-tabs__nav-scroll {
					max-width: 1100px;
					background: #3366ff !important;
					margin: 0 auto;
				}

				.el-tabs__item:hover {
					font-weight: 700;
					color: #fff;
				}

				.el-tabs__item.is-active {
					font-weight: 700;
					color: #fff;
				}

				.el-tabs__item {
					font-weight: 700;
					color: #fff;
				}

				.el-tabs__nav-wrap::after {
					background-color: #3366ff;
				}

				.el-tabs__active-bar {
					background-color: #fff;
				}
			}
		}
	}

	.headall {
		margin: 0 auto;

		.head {
			display: flex;
			height: 89px;
			max-width: 1100px;
			margin: 0 auto;
			margin-top: 30px;
		}

		.head-1 {
			// height: 20px;
			// margin-top: 30px;
			margin: auto 0;
		}

		.head-1 a {
			font-size: 18px;
			font-weight: 700;
			color: #333;
			text-decoration: none;
		}

		.head-1 a:hover {
			text-decoration: none;
			color: #333;
		}

		.mar20 {
			margin: 20px;
		}

		.marato {
			margin: 24px auto;
		}

		.el-header .so {
			float: right;
		}

		.so .el-input-group input {
			height: 32px;
			border: 1px solid #366cfc;
			border-radius: 0;
		}
	}

	.main-nav-center {
		width: 100%;
		position: absolute;
		left: 0;
		z-index: 0;

		// 轮播图 左右箭头 影藏  部分
		.el-carousel::v-deep .el-carousel__arrow {
			// display: none;
		}
	}

	.so::v-deep {
		.el-input-group__append {
			background-color: #366cfc !important;
			border: 1px solid #366cfc !important;
			color: #fff !important;
			border-radius: 0 !important;
			width: 66px;
			text-align: center;
		}

		.el-input__inner {
			border: 2px solid #366cfc !important;
			border-radius: 0 !important;
			width:423px;
			height: 42px !important;
		}
	}

	.main-nav-center-carousel {
		width: 100%;
		height: 100%;
		background-color: #f2f2f2;
	}
	.font-size15{
		font-size: 15px;
	}
</style>
