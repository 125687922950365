<template>
	<!-- 推荐 -->
	<div :class="themeClass">
    <div class="flex-row-center-center">
      <customerreceive/>
    </div>
		<div class="remlist-all">
			<div class="remlist" v-if=" huodongList.length == 0 ? false : true">
				<div class="remlistma">
					<div class="remlistma-le">
						<p class="font-weight700 p1">限时活动</p>
						<p class=" p2">镇店爆款，限时钜惠</p>
						<div class="morel pointer" @click="toLimitedTimeActivities">查看更多</div>
					</div>
					<div class="remlistma-right">
						<div class="flex-row">
							<div class="box-one-row-item-box pointer " id="box-one-s" v-for="(item, indexItem) in huodongList" :key="indexItem"
							 :style="Recommendations.length % 4 != 0 ? '':'margin-right:10px;'" @click="gotoIntegralGoodsDetails(item)">
								<div class="box-one-row-item flex-colum-space-between">
									<div class="box-one-row-buoy font-size14 font-color-fff font-weight700">限时</div>
									<div class="box-one-row-picture-box flex-row-center-center">
										<img class="box-one-row-picture" :src="item.fGoods.fPicturePath" />
									</div>
									<div class="box-one-row-content-box padding-10 flex-colum-between">
										<div class="box-one-row-content-title font-size13 oneline-show-hide">{{ item.fGoods.fGoodsFullName }}</div>

										<div class="box-two-integral font-size16 font-weight700 font-color-FF0000">
											<span v-if="item.fPointSwapGoodsPrice.fActivityID==27" data-v-fae5bece="" class="iconfont icon-jinbi1">
											</span>
											<span v-else>￥</span>
											{{item.fPointSwapGoodsPrice.fPointPrice }}
											<span class="font-size12 font-weight400" v-if="item.fPointSwapGoodsPrice.fActivityID!=27">元/吨</span>
											<span class="font-size12 font-weight400" v-else>积分/吨</span>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="remlist" style="margin-top: 30px;">
				<div class="rec">
					<div class="rec-le ">
						<img src="../../assets/u788.png" alt="">
						<div class="font-weight700 font-color-fff  font-size18 h54">店铺信息</div>
						<div class="pl10">
							<p class=" font-weight700 fc3 fontSize15">{{ shopData.fShopName }}</p>
							<p class="rec-p flex-row"><span class="margin-r-5">店铺星级:</span><el-rate v-model="starLevel" :colors="['#FF3300','#FF3300','#FF3300']" :disabled-void-color="'#e4e4e4'"
								 disabled></el-rate>
							</p>
							<p class="rec-p">
								开店时间:
								<span class="font-size12 fc3 ml3">{{ shopData.fBeginDate }}</span>
							</p>
							<p class="rec-p">
								关注人数:
								<span class="font-size12 fc3 ml3">{{ foucsData.focus }}人</span>
							</p>
						</div>
						<div class="pl10 btnboder" v-loading="loading">
							<div class="btnre pointer" @click="foucsChange">{{ foucsData.isFocus == 1 ? '已关注' : '关注' }}</div>
						</div>
						<div class="left-middle flex-colum border-F2F2F2 margin-b-10" style="margin-top: 45px;" v-if="ShopProfileList.length>0">
							<div class="padding-10 bg-fff radius5" style="height: 90px;overflow-y:scroll;">
								<div v-for="(item,index) in ShopProfileList" :key='index'>
									<p class="color-theme font-weight700" style="line-height: 22px; color: #0173FE;">{{item.fTitle}}</p>
									<div class="font-size12" style="line-height: 18px;">
										{{item.fContent}}
									</div>
								</div>
							</div>
						</div>
						<div class="left-middle flex-colum border-F2F2F2 margin-b-10"  :style=" ShopProfileList.length>0 ? 'margin-top: 10px;' : 'margin-top: 36px;' "  >
							<div class="font-weight700 margin-b-10" v-if="ruledetails.length>0">店铺规则</div>
							<div class="padding-10 bg-fff radius5" style="height: 230px;overflow-y:scroll;" v-if="ruledetails.length>0">
								<div v-for="(item,index) of ruledetails" :key="index">
									<div class="flex-colum">
										<div v-if="item.fIsTitle==2" class="color-theme font-weight700" style="line-height: 22px; color: #0173FE;">
											{{item.fValue}}
										</div>
										<div v-if="item.fIsTitle==1" class="font-size12 font-weight700" style="line-height: 18px;color: #000000;">
											{{item.fValue}}
										</div>
										<div v-if="item.fIsTitle==0" class="font-size12" style="line-height: 18px;">
											{{item.fValue}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="rec-right ml15">
						<!-- 秒杀活动开始 -->
						<!-- <div class="SeckillBillBox"v-if="SeckkillData.length == 0 ? false : true">
							<div style="padding:15px;display:flex;align-items: center;justify-content: space-between;">
							    <div style="display:flex;align-items: center;">
							    	<div style="font-size:28px;color:#FF0000;font-weight: 700;">限时秒杀</div>
							    	<div class="font-size18 font-weight700 font-color-FF0000 margin-l-19" >{{showhuodongtext}}</div>
							    	<countDowm v-if="countDownTime != null" :index="1" :remainTime="countDownTime"
							    		@countDownEnd="countDownEnd" :width="22" :height="22" :fontWeight="700" :fontSize="14"
							    		numberColor="#fff" bgColor="#ff0000" colonColor="#ff0000" :paddingRL="4"></countDowm>
							    </div> 
								<div  @click="GoSeckkill">更多></div>
							</div>
						    <div class="flex-row-space-between" style="margin: 0 15px 15px 15px">
								<div style="width:192px;height:252px;">
									<img style="width:192px;height:100%;" :src="SeckkillData[0].fSeckillBillGoodsPrice.fTitlePicPath" alt="" />
								</div>
								<div style="width:620px;display: flex;justify-content: space-between;">
						    	    <div class="box-one-row-item-boxSeckill pointer" v-for="(item, indexItem) in SeckkillData" :key="indexItem"
						    	 v-if="indexItem <= 3">
						    		<div class="box-one-row-item flex-colum-space-between" @click="gotoSeckkillGoodsDetails(item.fGoods.fGoodsID)">
						    			<div class="RecommendIntroduce-topSeckill position-relative" >
						    				  <div class="box-one-row-picture-box flex-row-center-center" >
						    					<img class="box-one-row-pictureSeckill" :src="item.fGoods.fPicturePath" />
						    				    
						    					<div style="top:10px"  class="box-one-row-buoyLR" v-if="item.fSeckillBillGoodsPrice !=null &&item.fSeckillBillGoodsPrice.fIconPath !=undefined && item.fSeckillBillGoodsPrice.fDispArea == 0  && item.fSeckillBillGoodsPrice.fIconPath != '' && item.fSeckillBillGoodsPrice.fIconPath !=null ">
						    						<img :src="item.fSeckillBillGoodsPrice.fIconPath" style="width: 100%;height: 100%;"/>
						    				    </div>
						    					
						    					<div class="box-one-row-buoy1LR" v-if="item.fSeckillBillGoodsPrice !=null &&item.fSeckillBillGoodsPrice.fIconPath !=undefined && item.fSeckillBillGoodsPrice.fDispArea == 1  && item.fSeckillBillGoodsPrice.fIconPath != '' && item.fSeckillBillGoodsPrice.fIconPath !=null ">
						    						<img :src="item.fSeckillBillGoodsPrice.fIconPath" style="width: 100%;height: 100%;"/>
						    					</div>
						    					
						    					<div class="box-one-row-buoy2LR" v-if="item.fSeckillBillGoodsPrice !=null && item.fSeckillBillGoodsPrice.fIconPath !=undefined && item.fSeckillBillGoodsPrice.fDispArea == 2  && item.fSeckillBillGoodsPrice.fIconPath != '' && item.fSeckillBillGoodsPrice.fIconPath !=null ">
						    						<img :src="item.fSeckillBillGoodsPrice.fIconPath" style="width: 100%;height: 100%;"/>
						    					</div>
						    					
						    					<div style="top:10px" class="box-one-row-buoy3LR" v-if=" item.fSeckillBillGoodsPrice !=null && item.fSeckillBillGoodsPrice.fIconPath !=undefined && item.fSeckillBillGoodsPrice.fDispArea == 2  && item.fSeckillBillGoodsPrice.fIconPath != '' && item.fSeckillBillGoodsPrice.fIconPath !=null ">
						    						<img :src="item.fSeckillBillGoodsPrice.fIconPath" style="width: 100%;height: 100%;"/>
						    					</div>
						    				
						    					<div   v-if='item.fGoods.fStatus == 2 ? true:false'   class='box-sellout'>
						    					  <img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
						    					</div>
						    			    </div>	
						    			    </div>
						    			<div class="box-one-row-content-box padding-10 flex-colum-between" >
						    				<div class="box-one-row-content-title font-size13" style="word-break: break-all;display: -webkit-box; color:#993300;
											-webkit-line-clamp: 2; -webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;max-height:35px;height:35px">
											{{item.fGoods.fGoodsFullName}}</div>
						    				<div v-if="item.fActivityIntegralMultiple>1" class="jinfen-box font-size12 border-FF0000 font-color-FF0000 padding-lr-5 radius2">{{item.fGoods.fActivityIntegralMultiple}}倍积分</div>
						    				<div class="box-one-row-content-price font-size18 font-weight700">
						    					￥<span>{{ item.fSeckillBillGoodsPrice.fPrice}}</span>
						    					<span class="font-size12 font-weight400">元/{{item.fGoods.fUnitName}}</span>
												<span style='color:#999999;font-weight:400;font-size:12px;text-decoration: line-through;margin-left:5px'>￥{{item.fSeckillBillGoodsPrice.fGoodsPrice}}</span>
						    				</div>
											
						    			</div> 
						    		</div>
						    	</div>
						        </div>
							</div>
						</div>
						<div style="background:#eaefff;height:15px;width:100%"></div> -->
						<!-- 秒杀活动结束 -->
						<div class="font-weight700  font-color-fff font-size18 h34">店铺商品</div>
						<div class="ml15 mt10">
							<div class="flex-row-space-between margin-b-15">
								<div class="flex-row">
									<div class="goodsTab-box text-center font-size13 pointer" :class="{'border-r-F2F2F2': goodsTabData.length == index + 1,'color-theme': item.checked}"
									 v-for="(item, index) of goodsTabData" :key="index" @click="goodsTabClick(item.id)" :style="goodsTabData.length % 4 != 0 ? '':'margin-right:10px;'">
										{{ item.name }}
										<span :class="item.checked ? 'el-icon-top' : ''"></span>
										<span :class="item.checked ? '' : 'el-icon-bottom'"></span>
									</div>
								</div>
								<div class="flex-row margin-tb-auto">
									<div>{{totalCount}}件商品</div>
									<div class="el-icon-arrow-left margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('-')"></div>
									<div>
										<span class="color-theme ">{{page}}</span>
										<span>/</span>
										<span>{{ Math.ceil(totalCount / pagesize) }}</span>
									</div>
									<div class="el-icon-arrow-right margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('+')"></div>
								</div>
							</div>
						</div>
						<svg-icon icon-class="NoShop" style="width: 850px;height: 900px;" v-if="recommendShopList.length == 0" />
						<div class="overh ml15" v-else>
							<div class="box-one-row-item-box pointer  borleft margin-r-10" v-for="(item, indexItem) in recommendShopList"
							 :key="indexItem">
								<div class="box-one-row-items flex-colum-space-between" @click="gotoStoreList(item.fGoodsID)">
									<!-- <div class="box-one-row-buoy font-size14 font-color-fff font-weight700">限时</div> -->
									<div class="box-one-row-picture-box2 flex-row-center-center position-relative">
										<img class="box-one-row-picture2" :src="item.fPicturePath" />
										<!-- 0右上角 -->
										<div class="box-one-row-buoyLR" v-if="item.couponInfo != null && item.couponInfo.fIconPath !=undefined && item.couponInfo.fDispArea == 0 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath !=null">
											<img :src="item.couponInfo.fIconPath" style="width: 100%;height: 100%;"/>
										</div>
										<!-- 1右下角 -->
										<div class="box-one-row-buoy1LR" v-if="item.couponInfo != null && item.couponInfo.fIconPath !=undefined && item.couponInfo.fDispArea == 1 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath !=null">
											<img :src="item.couponInfo.fIconPath" style="width: 100%;height: 100%;"/>
										</div>
										<!-- 2左下角 -->
										<div class="box-one-row-buoy2LR" v-if="item.couponInfo != null && item.couponInfo.fIconPath !=undefined && item.couponInfo.fDispArea == 2 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath !=null">
											<img :src="item.couponInfo.fIconPath" style="width: 100%;height: 100%;"/>
										</div>
										<!-- 3左上角 -->
										<div class="box-one-row-buoy3LR" v-if="item.couponInfo != null && item.couponInfo.fIconPath !=undefined && item.couponInfo.fDispArea == 3 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath !=null">
											<img :src="item.couponInfo.fIconPath" style="width: 100%;height: 100%;"/>
										</div>
											
									</div>
									<div class="box-one-row-content-boxs padding-10 flex-colum-between">
										<div class="box-one-row-content-title font-size13 twoline-show-hide" style="color:#993300;">{{ item.fGoodsFullName }}</div>
										<div v-if="item.fActivityIntegralMultiple>1" class="jinfen-box font-size12 border-FF0000 font-color-FF0000 padding-lr-5 radius2">{{item.fActivityIntegralMultiple}}倍积分</div>
										<div class="box-one-row-content-price font-size18 font-weight700">
											￥<span v-if='item.fGoodsMinPrice == item.fGoodsPrice ? false:true'>{{ item.fGoodsMinPrice }}~</span><span>{{ item.fGoodsPrice }}</span> 
											<span class="font-size12 font-weight400">元/{{item.fUnitName}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="ReCommendImgOne" v-if="hotShopList.length == 0 ? false : true">
					<div>
						<div class="ReCommendImg">
							<p>热销排行</p>
							<p>Hot Sale</p>
						</div>
						<div class="remlistma-right">
							<div class="flex-row">
								<div class="box-one-row-item-box pointer margin-r-10" v-for="(item, indexItem) in hotShopList" :key="indexItem"
								 v-if="indexItem <= 2">
									<div class="box-one-row-item flex-colum-space-between" @click="gotoStoreList(item.goods.fGoodsID)">
										<div class="box-one-row-buoy font-size14 font-color-fff font-weight700">No.{{ indexItem+1 }}</div>
											<div class="RecommendIntroduce-top">
											<div class="box-one-row-picture-box flex-row-center-center  position-relative">
												<img class="box-one-row-picture" :src="item.goods.fPicturePath" />
											    <!-- 0右上角 -->
												<div style="top:30px"  class="box-one-row-buoyLR" v-if="item.goods.couponInfo !=null && item.goods.couponInfo.fIconPath !=undefined && item.goods.couponInfo.fDispArea == 0  && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath !=null ">
													<img :src="item.goods.couponInfo.fIconPath" style="width: 100%;height: 100%;"/>
											    </div>
												<!-- 1右下角 -->
												<div class="box-one-row-buoy1LR" v-if="item.goods.couponInfo !=null && item.goods.couponInfo.fIconPath !=undefined && item.goods.couponInfo.fDispArea == 1  && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath !=null ">
													<img :src="item.goods.couponInfo.fIconPath" style="width: 100%;height: 100%;"/>
												</div>
												<!-- 2左下角 -->
												<div class="box-one-row-buoy2LR" v-if="item.goods.couponInfo !=null && item.goods.couponInfo.fIconPath !=undefined && item.goods.couponInfo.fDispArea == 2  && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath !=null ">
													<img :src="item.goods.couponInfo.fIconPath" style="width: 100%;height: 100%;"/>
												</div>
												<!-- 3左上角 -->
												<div style="top:30px" class="box-one-row-buoy3LR" v-if="item.goods.couponInfo !=null && item.goods.couponInfo.fIconPath !=undefined && item.goods.couponInfo.fDispArea == 3  && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath !=null ">
													<img :src="item.goods.couponInfo.fIconPath" style="width: 100%;height: 100%;"/>
												</div>
												<!--是否已售罄-->
												<div   v-if='item.goods.fStatus == 2 ? true:false'   class='box-sellout'>
												  <img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
												</div>
										</div>	
										    </div>
										<div class="box-one-row-content-box padding-10 flex-colum-between">
											<div class="box-one-row-content-title font-size13 oneline-show-hide">{{item.goods.fGoodsFullName}}</div>
											<div v-if="item.fActivityIntegralMultiple>1" class="jinfen-box font-size12 border-FF0000 font-color-FF0000 padding-lr-5 radius2">{{item.goods.fActivityIntegralMultiple}}倍积分</div>
											<div class="box-one-row-content-price font-size18 font-weight700">
												￥<span v-if='item.goods.fGoodsMinPrice == item.goods.fGoodsPrice ? false:true'>{{ item.goods.fGoodsMinPrice}}~</span><span>{{ item.goods.fGoodsPrice}}</span>
												<span class="font-size12 font-weight400">元/{{item.goods.fUnitName}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- v-if=" Recommendations.length == 0 ? false : true" -->
				<div class="aaa" style="border-bottom:none">
					<div class="ReCommendNav">
						<p>今日推荐</p>
						<p>recommend</p>
						<p>更多精彩，不要错过哦！</p>
					</div>
					<div class="RecommendIntroduce">
						<div class="RecommendIntroduceOne pointer" :style="Recommendations.length % 4 != 0 ? '':'margin-right:10px;'"
						 v-for="(item, indexItem) in Recommendations" :key="indexItem" @click="gotoStoreList(item.goods.fGoodsID)">
							<div class="RecommendIntroduce-top">
								<div class="box-one-row-picture-box flex-row-center-center position-relative">
									<img class="box-one-row-picture" :src="item.goods.fPicturePath" />
									<!-- 0右上角 -->
										<div class="box-one-row-buoy" v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fDispArea == 0 && item.goods.couponInfo.fIconPath != '' && item.couponInfo.fIconPath != null">
											<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
										</div>
										<!-- 1右下角 -->
										<div class="box-one-row-buoy1" v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fDispArea == 1 && item.goods.couponInfo.fIconPath != '' && item.couponInfo.fIconPath != null">
											<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
										</div>
										<!-- 2左下角 -->
										<div class="box-one-row-buoy2" v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fDispArea == 2 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != null">
											<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
										</div>
										<!-- 3左上角 -->
										<div class="box-one-row-buoy3" v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fDispArea == 3 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != null">
											<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
										</div>
								</div>
							</div>
							<div class="RecommendIntroduce-bottom">
								<div style=" font-size: 13px; color: #000000;line-height:20px;padding-top:10px;padding-left:10px;">{{ item.goods.fGoodsFullName }}</div>
								<div class="font-size12 border-FF0000 font-color-FF0000 padding-lr-5 radius2 margin-t-5" style="width:fit-content;margin-left: 10px;" v-if="item.goods.fActivityIntegralMultiple > 1">{{item.goods.fActivityIntegralMultiple}}倍积分</div>
								<div class="box-one-row-content-price font-size18 font-weight700 margin-t-5 margin-l-10">
									￥<span v-if='item.goods.fGoodsMinPrice == item.goods.fGoodsPrice ? false:true'>{{item.goods.fGoodsMinPrice}}~</span><span>{{item.goods.fGoodsPrice}}</span> 
									<span class="font-size12 font-weight400">元/吨</span>
								</div>
								<div style="display: flex;">
									<!-- <div class="u690_div radius2" v-if="item.goods.fShopTypeID == 1">{{item.goods.fShopTypeName}}</div> -->
									<!-- <span class="u690_span">3倍积分</span> -->
								</div>
								<!-- <div style="display: flex;">
									<div style=" width:140px;   font-size: 12px; color: #CCCCCC;margin-top:5px;margin-left:10px;" class="hide-text">{{ item.goods.fShopName }}</div>
									<span style="font-size: 12px;color: #1E1E1E; margin-top:5px;margin-left:10px;">进店 > </span>
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import customerreceive from './customerreceive.vue'
	import {
		mapGetters
	} from 'vuex';
	import pagination from '@/components/pagination/pagination.vue';
	import countDowm from '@/components/countDown/countDown.vue'
	export default {
		name: 'Header',
		components:{
			countDowm,
      customerreceive
		},
		props: {
			shopData: {
				type: Object,
				default: function() {
					return {};
				}
			},
			ruledetails: {
				type: Array,
				default: []
			},
			ShopProfileList: {
				type: Array,
				default: function() {
					return [];
				}
			},
			// starLevel: {
			// 	type: Number,
			// 	default: function() {
			// 		return 0;
			// 	}
			// },
			foucsData: {
				type: Object,
				default: function() {
					return {};
				}
			},
			hotShopList: {
				type: Array,
				default: function() {
					return [];
				}
			},
			recommendShopList: {
				type: Array,
				default: function() {
					return [];
				}
			},
			huodongList: {
				type: Array,
				default: function() {
					return [];
				}
			},
			Recommendations: {
				type: Array,
				default: function() {
					return [];
				}
			},
			page: { //当前页
				type: Number,
				default: function() {
					return 0;
				}
			},
			totalCount: { //总条数
				type: Number,
				default: function() {
					return 0;
				}
			},
			pagesize: { //每页显示条目个数
				type: Number,
				default: function() {
					return 8;
				}
			},
			loading: {
				type: Boolean,
				default: false
			},
			fShopUnitID:{
				type:String,
				default:-1
			}
			// SeckkillData:{  //父组件传过来的秒杀活动数据
			// 	type: Array,
			// 	default: function() {
			// 		return [];
			// 	}
			// },
			// countDownTime:{
			// 	type: Number,
			// 	default:0
			// },
			// showhuodongtext:{
			// 	type: String,
			// 	default:""
			// },
		},
		data() {
			return {
				// fPicturePath:'',
				getRecommendation: [],
				currentPage: 1, //当前页
				aaa: '自营',
				starLevel:0,
				goodsList3: [{
						tips: '限时',
						img: require('@/assets/imgs/Home/cementBag.png'),
						title: '天山牌普硅42天山牌普硅42啦啦啦啦啦啦啦啦啦啦啦啦啦啦',
						price: '200',
						cheap: '605',
						oneself: '自营',
						points: '3倍积分',
						shop: 'One自营旗舰店',
						go: '进店 > '
					},
					{
						tips: '限时',
						img: require('@/assets/imgs/Home/cementBag.png'),
						title: '天山牌普硅42天山牌普硅42啦啦啦啦啦啦啦啦啦啦啦啦啦啦',
						price: '200',
						cheap: '605',
						oneself: '自营',
						points: '3倍积分',
						shop: 'One自营旗舰店',
						go: '进店 > '
					},
					{
						tips: '限时',
						img: require('@/assets/imgs/Home/cementBag.png'),
						title: '天山牌普硅42天山牌普硅42啦啦啦啦啦啦啦啦啦啦啦啦啦啦',
						price: '200',
						cheap: '605',
						oneself: '自营',
						points: '3倍积分',
						shop: 'One自营旗舰店',
						go: '进店 > '
					},
					{
						tips: '限时',
						img: require('@/assets/imgs/Home/cementBag.png'),
						title: '天山牌普硅42天山牌普硅42啦啦啦啦啦啦啦啦啦啦啦啦啦啦',
						price: '200',
						cheap: '605',
						oneself: '自营',
						points: '3倍积分',
						shop: 'One自营旗舰店',
						go: '进店 > '
					}
				],
				goodsTabData: [{
						id: 1,
						name: '综合',
						checked: false,
					},
					{
						id: 2,
						name: '销量排序',
						checked: false,
						className: ''
					},
					{
						id: 3,
						name: '价格排序',
						checked: false,
					},
					// {
					// 	id: 4,
					// 	name: '默认排序',
					// 	checked: false,
					// 	className:'api/mall/ebshop/baseinfo/get-sort-by-fshopunits-weight-list'
					// },
				],
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
      console.log(this.ShopProfileList,'重点区域')
			// this.queryIntegralList();
			console.log("this.recommendShopList: " + JSON.stringify(this.recommendShopList));
			console.log(JSON.stringify(this.hotShopList))
			
		},
		beforeUpdate(){
			this.Star();//获取店铺星级
		},
		methods: {
			countDownEnd(){
				this.$emit('changeEnd')
	
			},
			//获取店铺星级
			Star(){
				 console.log(this.shopData,"9999999999999")
				 if(this.shopData != ''){
					 let sums = this.shopData.fEvaluationScore
					 this.starLevel = sums/20
				 }
			},
			GoSeckkill(){
				this.$router.push({
					name: 'Seckkill'
				})
			},
			toLimitedTimeActivities() {
				this.$router.push({
					name: 'LimitedTimeActivities',
					query: {
						shopID:this.fShopUnitID
					}
				})
			},
			// // 获取店铺
			// queryIntegralList() {
			//   this.ApiRequestPost(
			//     "/api/mall/ebactivity/point-swap-goods-price/get-count-by-fshopid-and-currentdate",
			//     {

			//                 id: 1
			// 	}
			//   ).then(
			//     (result) => {
			// 		console.log(result,"result8888888")
			// 		this.fIsShow = result.obj.fIsShow;
			// 		console.log(this.fIsShow,"this.fIsShow")
			// 		if(this.fIsShow == true){
			// 			this.ApiRequestPost("/api/mall/ebactivity/point-swap-goods-price/get-point-swap-goods-by-fshopid",{
			// 				 fAppType: 1,
			// 				 fShopID:1,
			// 			}).then(
			// 			(res)=>{
			// 				console.log(res,"res")
			// 				this.huodongList = res.obj;
			// 			}
			// 			)
			// 		}
			//     },
			//     (error) => {
			//       //   alert(JSON.stringify(error));
			//     }
			//   );
			// },
			//商品tab点击事件
			goodsTabClick(id) {
				for (let item of this.goodsTabData) {
					if (item.id == id) {
						item.checked = !item.checked;
						this.$emit('changeTab', id)
					} else {
						// item.checked = !item.checked;
					}
				}
			},
			pageChange(state) {
				this.$emit('pageChange', state);
			},
			foucsChange() {
				this.$emit('focusChange');
			},
			gotoStoreList(item) {
				// console.log("id: " + JSON.stringify(id));
				this.$router.push({
					name: 'GoodsDetails',
					query: {
						fGoodsID: item
					}
				})
			},
			gotoSeckkillGoodsDetails(item){
				this.$router.push({
					name: 'SeckkillGoodsDetails',
					query: {
						fGoodsID: item
					}
				})
			},
			gotoIntegralGoodsDetails(item) {
				var path = item.fPointSwapGoodsPrice.fActivityID == 27 ? "IntegralGoodsDetails" : "GoodsDetails";
				this.$router.push({
					name: path,
					query: {
						fGoodsID: item.fGoods.fGoodsID
					}
				})
			}
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.backgroundcolor-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.aaa {
		width: 869px;
		height: 410px;
		background-color: #ffffff;
		margin: 0px auto;
		margin-left: 231px;
		margin-bottom: 60px;
	}

	.rec-p {
		margin: 12px 0px;
	}

	.remlist-all {
		.remlist {
			background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 1) 100%);
			max-width: 1100px;
			margin: 0 auto;
			// height: 308px;

			.rec {
				display: flex;

				.rec-le {
					width: 218px;
					height: 245px;
					background-color: #fff;
					position: relative;

					.rec-p {
						font-size: 12px;
						line-height: 20px;
						color: #0173fe;
						marginl: 12px 0px;
					}

					.fc3 {
						color: #333 !important;
					}

					.ml3 {
						margin-left: 3px;
					}

					.pl10 {
						padding-left: 10px;
					}

					.btnboder {
						padding-top: 10px;
						border-top: 1px solid rgba(242, 242, 242, 1);
					}

					.btnre {
						text-align: center;
						width: 198px;
						height: 30px;
						line-height: 30px;
						background-color: rgba(1, 115, 254, 1);
						box-sizing: border-box;
						border-width: 1px;
						border-style: solid;
						border-color: rgba(1, 115, 254, 1);
						border-radius: 2px;
						-moz-box-shadow: none;
						-webkit-box-shadow: none;
						box-shadow: none;

						font-weight: 400;
						font-style: normal;
						color: #ffffff;
					}
				}

				.rec-right {
					width: 869px;
					background-color: #fff;
				}

				.ml15 {
					margin-left: 15px;
				}

				.mt10 {
					margin-top: 8px;
				}

				.h54 {
					position: relative;
					text-align: center;
					width: 100%;
					height: 34px;
					line-height: 34px;
					// background-color: #3c81e8;

					// background: #3c81e8 url(../../assets/u788.png) no-repeat 100% 30%;  
					// background: url(../../assets/u788.png);
				}

				.rec-le img {
					width: 100%;
					position: absolute;
					top: -20px;
					left: 0;
				}

				.h34 {
					// text-align: center;
					padding-left: 13px;
					height: 34px;
					line-height: 34px;
					background-color: #ffb901;
				}

				.goodsTab-box {
					width: 95px;
					height: 35px;
					line-height: 35px;
					border-left: 1px solid #f2f2f2;
					border-top: 1px solid #f2f2f2;
					border-bottom: 1px solid #f2f2f2;
				}
			}

			.remlistma {
        // mark.png
        // @/assets/collectpar.png
        background-image: url('../../assets/mark.png');
				height: 308px;
				background-color: #fda283;
				display: flex;
				padding-left: 35px;

				.remlistma-le {
					width: 45%;

					.p1 {
						margin: 0;
						margin-bottom: 12px;
						margin-top: 70px;
						font-size: 28px;
						color: #fff;
					}

					.p2 {
						margin: 0;
						margin-bottom: 20px;
						font-size: 18px;
						color: #fff;
					}

					.morel {
						padding-top: 7px;
						text-align: center;
						width: 103px;
						height: 28px;
						background: inherit;
						background-color: rgba(255, 255, 255, 1);
						border: none;
						border-radius: 30px;
						-moz-box-shadow: none;
						-webkit-box-shadow: none;
						box-shadow: none;
						font-size: 14px;
						color: #ff0000;
					}
				}

				.remlistma-right {
					width: 60%;
					padding-top: 40px;
				}
			}

			.box-one-row-content-box {
				width: 168px;
				// height: 45px;
				background-color: #fff;
			}

			.box-one-row-content-boxs {
				width: 168px;

				background-color: #fff;
			}

			.box-one-row-content-title {
				width: 168px;
				color: #333;
			}

			.box-one-row-buoy {
				min-width: 30px;
				height: 23px;
				background-image: linear-gradient(to right, #ff0000, #ff9999);
				position: absolute;
				left: 0;
				top: 7px;
				z-index: 10;
				padding: 0 10px;
				line-height: 24px;
			}
            .box-one-row-buoyLR {
            	min-width: 60px;
            	width: 60px;
            	height: 60px;
            	position: absolute;
            	right: 0;
            	top: 0;
            	z-index: 10;
            }
            .box-one-row-buoy1LR{
            	min-width: 60px;
            	width: 60px;
            	height: 60px;
            	position: absolute;
            	right: 0;
            	bottom: 0;
            	z-index: 10;
            }
            .box-one-row-buoy2LR{
            	min-width: 60px;
            	width: 60px;
            	height: 60px;
            	position: absolute;
            	left: 0;
            	bottom: 0;
            	z-index: 10;
            }
            .box-one-row-buoy3LR{
            	min-width: 60px;
            	width: 60px;
            	height: 60px;
            	position: absolute;
            	left: 0;
            	top: 0;
            	z-index: 10;
            }
			.box-one-row-picture-box {
				// width: 190px;
				height: 196px;
				// border-bottom: 2px solid rgba(242, 242, 242, 1);
			}

			.box-one-row-picture-box2 {
				width: 175px;
				height: 175px;
				margin-top: 10px;
				background-color: rgba(204, 204, 204, 0.266666666666667);
			}

			.box-one-row-item-box {
				width: 202px;
				height: 100%;
				// margin-right: 20px;
				position: relative;
				background-color: #fff;
				margin-top: 5px;
			}

			.box-one-row-content-price {
				width: 168px;
				color: #ff0000;
			}

			.box-one-row-picture {
				margin-top: 13px;
				height: 169px;
				width: 169px;
			}

			.box-one-row-picture2 {
				// margin-top: 13px;
				height: 151px;
				width: 150px;
			}

			.overh {
				overflow: hidden;
			}

			.borleft {
				float: left;
				margin-bottom: 20px;
			}

			.box-one-row-items {
				height: 265px;
				width: 202px;
				border: 1px solid #f2f2f2;
				position: relative;
			}
		}
	}

	.ReCommendImg {
		float: left;
	}

	.ReCommendImgOne {
		width: 869px;
		// height: 278px;
		margin-top: 21px;
		padding-bottom: 10px;
		margin-left: 230px;
		position: relative;
		// background-size:445px 278px;
		// background-position: left top;
		background: url(../../assets/imgs/Home/rexiao.png) no-repeat left top/445px 100%,
			linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 153, 0, 1) 100%, rgba(255, 153, 0, 1) 100%);
	}

	.ReCommendImg p:nth-child(1) {
		color: #ff0000;
		font-size: 28px;
		font-weight: bolder;
		margin: 30px 0px 0px 20px;
	}

	.ReCommendImg p:nth-child(2) {
		color: #ff0000;
		font-size: 18px;
		fonot-weight: bold;
		margin: 0px 100px 0px 60px;
	}

	#box-one-s {
		margin-top: -5px;
	}

	.ReCommendNav {
		width: 869px;
		height: 50px;
		background: url(../../assets/imgs/Home/renav.png) no-repeat;
		background-size: 869px 50px;
		// margin-top: 10px;
		// margin-left: 230px;
	}

	.ReCommendNav p {
		float: left;
	}

	.ReCommendNav p:nth-child(1) {
		font-size: 18px;
		color: #ffffff;
		font-weight: 700;
		margin-left: 13px;
		line-height: 50px;
	}

	.ReCommendNav p:nth-child(2) {
		opacity: 0.2;
		font-family: '阿里巴巴普惠体 Bold Oblique', '阿里巴巴普惠体 Regular', 阿里巴巴普惠体;
		font-weight: 700;
		font-style: oblique;
		font-size: 20px;
		color: rgb(255, 255, 255);
		line-height: 50px;
	}

	.ReCommendNav p:nth-child(3) {
		float: right;
		font-size: 13px;
		color: #ffffff;
		// margin-left: 500px;
		line-height: 50px;
		margin: 0
	}

	.RecommendIntroduce {
		width: 869px;
		height: 336px;
		display: flex;
		margin: 0 auto;
		// margin-left:210px;
		// justify-content: space-evenly;

	}

	.RecommendIntroduceOne {
		width: 202px;
		height: 290px;
		border: 1px solid rgba(204, 204, 204, 1);
	}

	.RecommendIntroduce-top {
		width: 202px;
		height: 202px;
		background-color: #ffffff;
	}

	.RecommendIntroduce-bottom {
		width: 202px;
		height: 88px;
		background-color: #fff3db;
	}

	.u690_div {
		background: linear-gradient(45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 1) 0%, rgba(255, 153, 153, 1) 100%, rgba(255, 153, 153, 1) 100%);
		// width: 30px;
		padding: 0 3px;
		height: 15px;
		line-height: 15px;
		color: #ffffff;
		font-size: 12px;
		margin-left: 10px;
		margin-top: 10px;
		text-align: center;
	}

	.u690_span {
		display: block;
		width: 47px;
		height: 15px;
		font-size: 12px;
		color: #ff0000;
		text-align: center;
		border: 1px solid #ff0000;
		margin-top: 10px;
		margin-left: 10px;
	}
	.jinfen-box{
		width:fit-content;
		margin: 2px 0;
	}
	.box-one-row-buoy {
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
	}
	.box-one-row-buoy1{
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 10;
	}
	.box-one-row-buoy2{
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 10;
	}
	.box-one-row-buoy3{
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
	}
	.box-sellout {
	  min-width: 70px;
	  width: 100px;
	  height: 100px;
	  position: absolute;
	  right: 15px;
	  top: 15px;
	  z-index: 100;
	  padding: 0 10px;
	  line-height: 24px;
	}
	.fontSize15{
		font-size: 15px;
		line-height: 20px;
		margin: 12px 0;
	}
	.Introduction-con-box {
		font-weight: 400;
		color: #666666;
		line-height: 22px;
		font-size: 13px;
		padding: 0px 15px;
		.shop-brief-introduction {
			text-indent: 2em;
		}
	}
	.Introduction-con-title {
		font-weight: bold;
		color: #0173fe;
		margin: 0px 0px 13px 0px;
		padding-top: 15px;
	}
	.SeckillBillBox{
		width: 869px;
		// height: 410px;
		// background-color:#fda283;
		margin: 0px auto;
	
		// margin-bottom: 15px;
		// padding-bottom:15px;
		.box-one-row-item-boxSeckill {
			width: 192px;
			height: 252px;
			border:1px solid #cccccc;
			position: relative;
			background-color: #fff;
			// margin-top: 5px; 
			.box-one-row-buoy {
				min-width: 30px;
				height: 23px;
				background-image: linear-gradient(to right, #ff0000, #ff9999);
				position: absolute;
				left: 0;
				top: 7px;
				z-index: 10;
				padding: 0 10px;
				line-height: 24px;
			}
			.box-one-row-picture-box {
				width: 139px;
				height: 139px;
				// border-bottom: 2px solid rgba(242, 242, 242, 1);
			}
			.box-one-row-pictureSeckill  {
				// margin-top: 13px;
				height: 139px;
				width: 139px;
			}
			.RecommendIntroduce-topSeckill {
				width: 192px;
				height: 167px;
				background-color: #ffffff;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.box-one-row-buoyLR {
				min-width: 50px;
				width: 50px;
				height: 24px;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 10;
			}
			.box-one-row-buoy1LR{
				min-width: 50px;
				width: 50px;
				height: 24px;
				position: absolute;
				right: 0;
				bottom: 0;
				z-index: 10;
			}
			.box-one-row-buoy2LR{
				min-width: 50px;
				width: 50px;
				height: 24px;
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 10;
			}
			.box-one-row-buoy3LR{
				min-width: 50px;
				width: 50px;
				height: 24px;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 10;
			}
		}
	}
</style>
