<template>
	<!-- 商品 -->
	<div class="CommodityList">
		
	<div class="ml15 mt10 padding-t-20 padding-10">
		<div class="flex-row-space-between margin-b-15">
			<div class="flex-row">
				<div class="goodsTab-box text-center font-size13 pointer" :class="{'border-r-F2F2F2': goodsTabData.length == index + 1,'color-theme': item.checked}"
				 v-for="(item, index) of goodsTabData" :key="index" @click="goodsTabClick(item.id)" :style="goodsTabData.length % 4 != 0 ? '':'margin-right:10px;'">
					{{ item.name }}
					<span :class="item.checked ? 'el-icon-top' : ''"></span>
					<span :class="item.checked ? '' : 'el-icon-bottom'"></span>
				</div>
			</div>
			<div class="flex-row margin-tb-auto">
				<div>{{totalCount}}件商品</div>
				<div class="el-icon-arrow-left margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('-')"></div>
				<div>
					<span class="color-theme ">{{page}}</span>
					<span>/</span>
					<span>{{ Math.ceil(totalCount / pagesize) }}</span>
				</div>
				<div class="el-icon-arrow-right margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('+')"></div>
			</div>
		</div>
	</div>
		<div  class="flex-row"  style="flex-wrap: wrap;padding-left: 5px;">
			<div class="RecommendIntroduceOne" @click="jumpGoodDetails(item)" v-for="(item, indexItem) in recommendShopList" :key="indexItem+'2'" >
				<div class="RecommendIntroduce-top">
					<div class="box-one-row-picture-box flex-row-center-center position-relative">
						<img class="box-one-row-picture" :src="item.fPicturePath"></img>
						<!-- 0右上角 -->
							<div class="box-one-row-buoy" v-if="item.couponInfo != null&& item.couponInfo.fIconPath != undefined && item.couponInfo.fDispArea == 0 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath != null">
								<img :src="item.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
							</div>
							<!-- 1右下角 -->
							<div class="box-one-row-buoy1" v-if="item.couponInfo != null&& item.couponInfo.fIconPath != undefined && item.couponInfo.fDispArea == 1 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath != null">
								<img :src="item.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
							</div>
							<!-- 2左下角 -->
							<div class="box-one-row-buoy2" v-if="item.couponInfo != null&& item.couponInfo.fIconPath != undefined && item.couponInfo.fDispArea == 2 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath != null">
								<img :src="item.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
							</div>
							<!-- 3左上角 -->
							<div class="box-one-row-buoy3" v-if="item.couponInfo != null&& item.couponInfo.fIconPath != undefined && item.couponInfo.fDispArea == 3 && item.couponInfo.fIconPath != '' && item.couponInfo.fIconPath != null">
								<img :src="item.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;"> 
							</div>
					</div>
				</div>
				<div class="RecommendIntroduce-bottom">
					<div style=" font-size: 13px; color: #000000;line-height:20px;padding-top:10px;padding-left:10px;">{{item.fGoodsFullName}}</div>
					<div class="box-one-row-content-price font-size18 font-weight700 margin-t-10 margin-l-10">
						￥<span v-if='item.fGoodsMinPrice == item.fGoodsPrice ? false:true'>{{ item.fGoodsMinPrice }}~</span><span>{{ item.fGoodsPrice }}</span>  <span class="font-size12 font-weight400">元/吨</span>
					</div>
					<div class="flex-row-align-center margin-l-10">
						<!-- <div class="u690_div">{{item.fShopTypeName}}</div> -->
						<div v-if="item.fActivityIntegralMultiple>1" class="jinfen-box font-size12 border-FF0000 font-color-FF0000 padding-lr-5 radius2">{{item.fActivityIntegralMultiple}}倍积分</div>
					</div>
					<div style="display: flex;">
						<div style="  width: 140px;  font-size: 12px; color: #CCCCCC;margin-top:5px;margin-left:10px;" class="hide-text">{{item.fShopName}}</div>
					</div>
				</div>
			</div>
			
		</div>

	</div>
</template>

<script>
	export default {
		props: {
			recommendShopList: {
				type: Array,
				default: function() {
					return [];
				}
			},
			page: { //当前页
				type: Number,
				default: function() {
					return 0;
				}
			},
			totalCount: { //总条数
				type: Number,
				default: function() {
					return 0;
				}
			},
			pagesize: { //每页显示条目个数
				type: Number,
				default: function() {
					return 8;
				}
			},
			Recommendations: {
				type: Array,
				default: function() {
					return [];
				}
			},
			shopData: {
				type: Object,
				default: function() {
					return {};
				}
			},
		},
		data() {

			return {
				goodsTabData: [{
						id: 1,
						name: '综合',
						checked: true,
					},
					{
						id: 2,
						name: '销量排序',
						checked: false,
						className: ''
					},
					{
						id: 3,
						name: '价格排序',
						checked: false,
					},
				],
			}
		},
		// 页面创建完成后,请求全部商品
		mounted(){
			 console.log('全部商品列表',this.recommendShopList)
		},
		methods: {
			// 跳转商品详情
			jumpGoodDetails(item) {
				this.$router.push({
					name: 'GoodsDetails',
					query: {
						fGoodsID: item.fGoodsID
					}
				})
			},
			pageChange(state) {
				console.log('页面点击事件')
				this.$emit('pageChangeall', state);
			},
			goodsTabClick(id) {
				for (let item of this.goodsTabData) {
					if (item.id == id) {
						item.checked = !item.checked;
						this.$emit('changeTab', id)
					} else {
						// item.checked = !item.checked;
					}
				}
			},
		},
	}
</script>

<style lang="scss" scoped="scoped">
	.CommodityList {
		width: 1100px;
		height: 100%;
		background: #FFFFFF;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		// justify-content: space-evenly;
		flex-wrap: wrap;
		margin-bottom: 30px;
		padding-bottom: 14px;
		// 为了显得页面不空
		min-height: 706px;
	}

	.RecommendIntroduceOne {
		width: 202px;
		height: 336px;
		border: 1px solid rgba(204, 204, 204, 1);
		margin-right: 15px;
		// float:left;
		// margin-bottom: 15px;
		margin-top: 15px;
		// padding:0px 13px;
	}

	.RecommendIntroduce-top {
		width: 202px;
		height: 202px;
		background-color: #FFFFFF;
	}

	.RecommendIntroduce-bottom {
		width: 202px;
		height: 134px;
		// background-color: #FFF3DB;
	}

	.u690_div {
		background: linear-gradient(45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 1) 0%, rgba(255, 153, 153, 1) 100%, rgba(255, 153, 153, 1) 100%);
		width: 30px;
		height: 15px;
		color: #FFFFFF;
		font-size: 12px;
		text-align: center;
		margin: 0 10px 0 0 !important;
		// float:left;
	}

	.u690_span {
		display: block;
		width: 47px;
		height: 15px;
		font-size: 12px;
		color: #FF0000;
		text-align: center;
		border: 1px solid #FF0000;
		margin-top: 10px;
		margin-left: 10px;
	}

	.box-one-row-picture-box {
		width: 196px;
		height: 196px;
		// border-bottom: 2px solid rgba(242, 242, 242, 1);
	}

	.box-one-row-picture {
		width: 132px;
		height: 176px;
	}

	.box-one-row-content-price {
		width: 168px;
		color: #ff0000;
	}

	.box-one-row-picture2 {
		// margin-top: 13px;
		height: 151px;
	}

	.jinfen-box {
		width: fit-content;
		height: 13px;
		line-height: 13px;
		margin-left: 10px;
		margin: 0 !important;
	}
	.goodsTab-box {
		width: 95px;
		height: 35px;
		line-height: 35px;
		border-left: 1px solid #f2f2f2;
		border-top: 1px solid #f2f2f2;
		border-bottom: 1px solid #f2f2f2;
	}
	.box-one-row-buoy {
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
	}
	.box-one-row-buoy1{
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 10;
	}
	.box-one-row-buoy2{
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 10;
	}
	.box-one-row-buoy3{
		min-width: 60px;
		width: 60px;
		height: 60px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
	}
</style>
